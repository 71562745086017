<template>
    <header id="home">
        <!-- <div class="hero">
            <div class="hero__image animation--hero">
                <div class="hero__image--overlay"></div>
                <div class="hero__child hero__child--secondary hero__child--secondary2 animation--floating"></div>
                <div class="hero__child hero__child--secondary animation--floating"></div>
            </div>
            <p class="hero__title animation--title-in">Unique way of meeting your adventurous expectations with</p>
            <p class="hero__subtitle animation--title-in">Check In Tourism</p>
        </div> -->
        <vueper-slides :touchable="false" :bullets="false" fade autoplay :dragging-distance="50" fixed-height="100vh">
            <vueper-slide>
                <template #content>
                    <div class="hero">
                        <div class="hero__image animation--hero">
                            <div class="hero__image"></div>
                            <div class="hero__child hero__child--secondary hero__child--secondary2 animation--floating">
                            </div>
                            <div class="hero__child hero__child--secondary animation--floating"></div>
                        </div>
                        <p class="hero__title animation--title-in">{{ $t('hero.main_title') }}
                        </p>
                        <p class="hero__subtitle animation--title-in">{{ $t('hero.desc') }}</p>
                    </div>
                </template>
            </vueper-slide>
            <vueper-slide>
                <template #content>
                    <div class="hero">
                        <div class="hero__image animation--hero">
                            <div class="hero__image2"></div>
                            <div class="hero__child hero__child--secondary hero__child--secondary2 animation--floating">
                            </div>
                            <div class="hero__child hero__child--secondary animation--floating"></div>
                        </div>
                        <p class="hero__title animation--title-in">{{ $t('hero.main_title') }}
                        </p>
                        <p class="hero__subtitle animation--title-in">{{ $t('hero.desc') }}</p>
                    </div>
                </template>
            </vueper-slide>
            <vueper-slide>
                <template #content>
                    <div class="hero">
                        <div class="hero__image animation--hero">
                            <div class="hero__image3"></div>
                            <div class="hero__child hero__child--secondary hero__child--secondary2 animation--floating">
                            </div>
                            <div class="hero__child hero__child--secondary animation--floating"></div>
                        </div>
                        <p class="hero__title animation--title-in">{{ $t('hero.main_title') }}
                        </p>
                        <p class="hero__subtitle animation--title-in">{{ $t('hero.desc') }}</p>
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
        <!-- <carousel :autoplay="true" :nav="false" :loop="true"  :dots="false" :autoplayTimeout="7000" :smartSpeed="3000" :items="1">
            <div class="slide one">
                <div class="header-content" v-scrollanimation>
                    <h1 class="main-title"> {{ $t('hero.main_title') }}</h1>
                    <h4 class="second-title">{{ $t('hero.desc') }}</h4>
                </div>
            </div>
            <div class="slide two">
                <div class="header-content" v-scrollanimation>
                    <h1 class="main-title"> dfsedfsefsf</h1>
                    <h4 class="second-title">cfasdfsdfsdfsd</h4>
                </div>
            </div>
            <div class="slide three">
                <div class="header-content" v-scrollanimation>
                    <h1 class="main-title"> dfsedfsefsf</h1>
                    <h4 class="second-title">cfasdfsdfsdfsd</h4>
                </div>
            </div>
        </carousel> -->
        <!-- <div class="overlay">
            <div class="container" v-scrollanimation>
                <div class="row justify-content-center">
                    <div class="col-md-9 col-sm-12 child">
                        <div class="header-inner">
                            <div class="header-content">
                                <h1 class="main-title"> {{ $t('hero.main_title') }}</h1>
                                <h4 class="second-title">{{ $t('hero.desc') }}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </header>
</template>

<script>
import carousel from 'vue-owl-carousel'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    name: "hero",
    components: {
        carousel,
        VueperSlides,
        VueperSlide
    },
    data() {
        return {
            display: '',
            direction: false,
            lang: null,
            slides: [
                {
                    image: require('@/assets/img/1.webp'),
                },
                {
                    image: require('@/assets/img/2.webp'),
                },
            ]
        };
    },
    computed: {
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
    },
    mounted() {
        this.initDir()
    }

};
</script>

<style lang="scss">
.hero {
    overflow: hidden;
    position: relative;

    display: block;
    width: 100%;
    height: 100vh;

    &__image {
        position: absolute;
        width: 100%;
        height: 100vh;
        clip: rect(0, auto, auto, 0);
        background-image: url(@/assets/img/hero5.jpg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__image2 {
        position: absolute;
        width: 100%;
        height: 100vh;
        clip: rect(0, auto, auto, 0);
        background-image: url(@/assets/img/hero4.jpg);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }
    &__image3 {
        position: absolute;
        width: 100%;
        height: 100vh;
        clip: rect(0, auto, auto, 0);
        background-image: url(@/assets/img/2.webp);
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__child {
        position: fixed;

        background-repeat: no-repeat;
        backface-visibility: hidden;
        opacity: .8;

        &--primary {
            top: -120px;
            left: 50px;

            width: 819px;
            height: 1621px;

            background-image: url(@/assets/img/cloud-2.png);
        }

        &--secondary {
            top: 100px;
            right: 400px;
            width: 300px;
            height: 500px;
            background-size: 318px auto;
            background-image: url(@/assets/img/cloud-1.png);
        }

        &--secondary2 {
            top: 70px;
            right: 1000px;
            width: 300px;
            height: 500px;
            background-size: 318px auto;
            background-image: url(@/assets/img/cloud-1.png);
        }
    }

    &__title {
        position: absolute;
        bottom: 40vh;
        left: 10vw;
        right: 10vw;
        z-index: 1;

        opacity: 0;

        font-size: 30px;
        // line-height: calc(6px * 6);

        color: white;

        // font-family: 'Eczar', sans-serif;
        font-weight: bold;

        text-align: center;

        @media (min-width: 768px) {
            bottom: 37vh;
            font-size: 45px;
            // line-height: calc(6px * 10);
        }
    }

    &__subtitle {
        position: absolute;
        bottom: 25vh;
        left: 24vw;
        right: 24vw;

        color: white;

        // font-family: 'Gentium Basic', serif;
        font-size: 30px;
        // line-height: 32px;
        text-align: center;
    }
}

/**
* Animation 
*/

.animation {
    &--floating {
        animation-name: floating;
        animation-duration: 90000ms;
        animation-iteration-count: infinite;
    }

    &--title-in {
        animation: title-in 1000ms forwards ease-in-out 750ms;

    }

    &--from-top {
        animation: from-top 350ms forwards cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &--from-bottom {
        animation: from-bottom 350ms forwards cubic-bezier(0.65, 0.05, 0.36, 1);
    }

    &--hero {
        animation-name: hero;
        animation-duration: 80000ms;
        animation-iteration-count: infinite;
    }
}

/**
* Keyframes for specific animation
*/
@keyframes floating {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes hero {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes title-in {
    0% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-50%);
    }

    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

@keyframes from-top {
    0% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-55%);
    }

    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

@keyframes from-bottom {
    0% {
        visibility: hidden;
        opacity: 0;
        transform: translateY(15%);
    }

    100% {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}




// .hero {
//     margin-top: 75px;
// }

// .hero .vueperslides__arrow {
//     color: #fff;
// }

// .hero .vueperslide {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .hero .slide {
//     background-image: url(@/assets/img/1.webp);
//     position: relative;
//     height: 85vh;
//     background-position: 50%;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
// }

// .hero .overlay {
//     height: 100%;
//     width: 100%;
//     background: linear-gradient(270deg, #13436a57, #0c273a9d);
// }

// html:lang(en) .hero .main-title {
//     font-family: Saira, sans-serif;
//     color: #fff;
// }

// html:lang(ar) .hero .main-title {
//     color: #fff;
// }

// .hero .second-title {
//     color: #fff;
// }

// .hero .main-title {
//     font-size: 50px;
//     font-weight: 800;
// }

// @media (max-width: 991px) {
//     html:lang(ar) .hero .main-title {
//         margin: 0px 10px 30px 40px;
//     }
// }

// @media (max-width: 991px) {
//     .hero .main-title {
//         font-size: 28px;
//         margin: 0px 0px 30px 0px;
//     }

//     .hero .second-title {
//         font-size: 20px;
//     }

//     .hero-image {
//         height: 60% !important;
//     }
// }


// .hero .header-content {
//     z-index: 200;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//     // margin-right: 35px;
//     // padding-top: 150px
// }

// // .hero .one .before-enter {
// //     opacity: 0;
// //     transform: translateY(150px);
// //     transition: all 1.7s ease-out;
// // }

// // .hero .one .enter {
// //     opacity: 1;
// //     transform: translateY(0px);
// // }

// .hero .two .before-enter {
//     opacity: 0;
//     transform: translateY(150px);
//     transition: all 1.7s ease-out;
// }

// .hero .two .enter {
//     opacity: 1;
//     transform: translateY(0px);
// }
</style>