<template>
    <section class="application">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 left">
                    <div v-scrollanimation>
                        <h2 class="app-title">{{ $t('app.main_title') }}</h2>
                        <p class="app-desc">{{ $t('app.desc') }}</p>
                    </div>
                </div>
                <div class="col-lg-6 right">
                    <div class="row" style="row-gap: 20px" v-scrollanimation>
                        <div class="col-md-6">
                            <v-img src="@/assets/img/app-store.png" class="app-buttom" max-width="200px"></v-img>
                        </div>
                        <div class="col-md-6">
                            <v-img src="@/assets/img/google-play.png"  class="app-buttom" max-width="200px"></v-img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>


export default {
    components: {

    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    },
}
</script>
<style lang="scss">
.application {
    background-image: url(@/assets/img/1.webp);
    position: relative;
    z-index: 0;
    padding: 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.application::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--v-secondary-base);
    opacity: .3;
    z-index: -1;
    content: '';
}

.application .app-title {
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
}
.application .app-buttom:hover{
    cursor: pointer;
}
.application .app-desc {
    color: #fff;
    font-size: 18px;
}

.application .left {
    text-align: justify;
}

html:lang(en) .application .left .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .application .left .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .application .left .enter,
html:lang(en) .application .left .enter {
    opacity: 1;
    transform: translateX(0px);
}

html:lang(en) .application .right .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .application .right .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .application .right .enter,
html:lang(en) .application .right .enter {
    opacity: 1;
    transform: translateX(0px);
}
</style>