<template>
    <section class="counter">
        <div class="container">
            <div class="row" style="row-gap: 40px;text-align: justify;">
                <div class="col-sm-4 ">
                    <div class="counter-value">
                        <ICountUp :delay="delay" :endVal="200" :options="options" @ready="onReady" />
                    </div>
                    <h6 class="counter-title">
                        {{$t('resturant.main_title')}}
                    </h6>
                </div>
                <div class="col-sm-4 ">
                    <div class="counter-value">
                        <ICountUp :delay="delay" :endVal="70" :options="options" @ready="onReady" />
                    </div>
                    <h6 class="counter-title">
                        {{$t('event.main_title')}}
                    </h6>

                </div>
                <div class="col-sm-4 ">
                    <div class="counter-value">
                        <ICountUp :delay="delay" :endVal="125" :options="options" @ready="onReady" />
                    </div>
                    <h6 class="counter-title">
                        {{$t('tour.main_title')}}
                    </h6>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ICountUp from 'vue-countup-v2';

export default {
    components: {
        ICountUp
    },
    data() {
        return {
            delay: 1000,
            options: {
                useEasing: true,
                useGrouping: true,
                suffix: ' +',
                enableScrollSpy: true
            }
        };
    },
    methods: {
        onReady: function (instance, CountUp) {
            instance.start();
        }

    },
    mounted() {
    },
}
</script>
<style lang="scss">
.counter {
    padding: 50px;
    background: rgba(224, 224, 224, 0.356)
}

.counter .counter-value {
    color: var(--v-secondary-base);
    font-size: 35px;
    font-weight: bold;
}

.counter .counter-title {
    font-size: 17px;
    color: gray;
    // font-weight: bold;
}
</style>