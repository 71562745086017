<template>
  <v-app :class="[isAr ? 'ar' : 'en']">
    <div>
      <v-main>
        <router-view />
      </v-main>
    </div>

  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  data: () => ({
    lang: '',
  }),
  computed: {
    isAr() {
      this.lang = localStorage.getItem("lang")
      let htmlElement = document.documentElement
      if (this.lang == 'ar') {
        htmlElement.setAttribute('lang', 'ar')
        return true
      }
      else {
        htmlElement.setAttribute('lang', 'en')
        return false
      }
    },
  },
  created() {
    this.lang = localStorage.getItem("lang")
    if (this.lang == null)
      localStorage.setItem("lang", "en")
  },
};
</script>

<style lang="scss">
html:lang(ar) * {
  letter-spacing: 0 !important;
}

// .theme--light.v-application {
//     background: #f2f2f2 !important;
// }

@font-face {
  font-family: "Bahnschrift-Regular";
  src: local("Bahnschrift-Regular"),
    url(assets/font/Bahnschrift-Regular.ttf) format("truetype");
  font-display: swap
}

@font-face {
  font-family: "Almarai";
  src: local("Almarai"),
    url(assets/font/Almarai-Regular.ttf) format("truetype");
}

.en {
  font-family: "Bahnschrift-Regularr", sans-serif !important;
  direction: ltr;
}

.ar {
  font-family: 'Almarai', sans-serif !important;
  direction: rtl;
}
</style>
