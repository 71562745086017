<template>
    <v-card class="event-card mx-auto" max-width="400" style="margin-top: 30px">
        <v-img class="white--text align-end" height="200px" :src="item.media_urls.url"></v-img>
        <v-card-title style="color: var(--v-primary-base);">{{ item.title }}</v-card-title>
        <v-card-subtitle class="pb-2">
            <div class="row" style="margin-top: 10px">
                <b-icon icon="geo-alt-fill" class="event-icon" style="margin-top: 3px;"></b-icon>
                {{ item.city.name }}, {{ item.location }}
            </div>
            <div class="row" style="margin-top: 15px">
                <b-icon icon="calendar-date" class="event-icon" style="margin-top: 3px;"></b-icon>
                {{ item.start_date.substring(0, 10) }}, {{ item.end_date.substring(0, 10) }}
            </div>
            <div class="row" style="margin-top: 15px">
                <b-icon icon="cash" class="event-icon" style="margin-top: 3px;"></b-icon>
                {{ item.price }}
            </div>
        </v-card-subtitle>
        <v-card-actions class="btn-card" style="margin-top: 10px;">
            <v-btn @click="display(item.id)" color="accent" text>
                {{ $t('explore') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

export default {
    props: ["item"],
    data() {
        return {
        };
    },
    methods: {
        display(id) {
            this.$router.replace({ name: 'event-details', params: { id: id } })
        },
    },
    mounted() {
    }
}
</script>
<style lang="scss">
html:lang(en) .event-icon{
    margin-right: 10px;
}
html:lang(ar) .event-icon{
    margin-left: 10px
}
html:lang(ar) .v-card{
    direction: rtl
}
html:lang(en) .v-card{
    direction: ltr
}
.event-card.v-card {
    box-shadow: 0 0 14px 6px #8080801a;
    // height: 450px;
    transition: all .4s;
    margin: 20px 15px;
}

.event-card.v-card:hover {
    box-shadow: 0px 11px 14px rgb(106 106 106 / 40%);
    transform: translateY(-10px);
    transition: all .4s;
}

.event-card .v-card__title {
    font-size: 20px;
}

.event-card .v-card__text {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: start;
}

</style>
