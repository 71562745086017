import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item != null)?_c(VCard,{staticClass:"guid-card mx-auto",attrs:{"max-width":"344"}},[_c(VImg,{staticClass:"overlay",attrs:{"src":_vm.item.media.url,"height":"200px"}},[_c('ul',{staticClass:"row social-links"},[_c('li',[_c('a',{staticClass:"social email",attrs:{"href":'mailto:' + _vm.item.email}},[_c('b-icon',{staticClass:"email",attrs:{"icon":"envelope-fill","aria-hidden":"true"}})],1)]),_c('li',[_c('a',{staticClass:"social phone",attrs:{"href":'tel:' + _vm.item.phone}},[_c('b-icon',{staticClass:"phone",attrs:{"icon":"telephone-fill","aria-hidden":"true"}})],1)])])]),_c(VCardTitle,{on:{"click":function($event){return _vm.display(_vm.item.id)}}},[_vm._v(" "+_vm._s(_vm.item.fname)+" "+_vm._s(_vm.item.lname)+" ")]),_c(VCardSubtitle,{staticClass:"row",staticStyle:{"margin-top":"0px"}},[_c('b-icon',{staticClass:"guid-icon",staticStyle:{"margin-top":"3px"},attrs:{"icon":"geo-alt-fill"}}),_vm._v(" "+_vm._s(_vm.item.city.name)+", "+_vm._s(_vm.item.address)+" ")],1),_c(VCardSubtitle,{staticClass:"row",staticStyle:{"column-gap":"10px","margin-bottom":"10px","margin-top":"0px"}},_vm._l((_vm.item.type),function(item2,i){return _c('div',{key:i},[(item2 == 'translator' )?_c(VChip,{attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.translator)+" ")]):_vm._e(),(item2 == 'tourist guide')?_c(VChip,{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.guid)+" ")]):_vm._e()],1)}),0)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }