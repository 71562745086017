<template>
    <div class="list" v-if="load">
        <navbar />
        <div class="list-image-service">
            <div class="overlay-service">
                <h1 class="main-title">{{ $t('filter.service') }}</h1>
            </div>
        </div>
        <div class="list-content container">
            <div class="row">
                <div class="col-lg-4">
                    <v-card class="filter-card" style="text-align: justify">
                        <h4>{{ $t('filter.property') }}</h4>
                        <v-divider></v-divider>
                        <div class="property">
                            <v-radio-group row v-model="is_featured">
                                <v-radio :label="$t('filter.all')" value="0"></v-radio>
                                <v-radio :label="$t('filter.featured')" value="1"></v-radio>
                            </v-radio-group>
                            <v-select outlined v-model="category_id" :reverse="direction" :items="category_list"
                                item-text="name" item-value="id" :label="$t('filter.category')"></v-select>
                            <v-text-field outlined :reverse="direction" v-model="name"
                                :label="$t('filter.name')"></v-text-field>
                            <v-select outlined v-model="sort_by_name_id" :reverse="direction" :items="sort_by_name"
                                item-text="name" item-value="id" :label="$t('filter.sort_by_name')"></v-select>
                            <v-select outlined v-model="sort_by_date_id" :reverse="direction" :items="sort_by_date"
                                item-text="name" item-value="id" :label="$t('filter.sort_by_date')"></v-select>
                            <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                                item-value="id" :label="$t('filter.city')"></v-select>

                            <div v-for="(item, index) in terms_list" :key="index">
                                <v-select outlined v-model="terms_value[item.id]" :reverse="direction"
                                    :items="item.core_terms" item-text="name" item-value="id" :label="item.name"></v-select>
                            </div>

                            <div class="row" style="margin-top: 10px">
                                <p>{{ $t('filter.rate') }}</p>
                                <v-rating v-model="rating" style=" top: -10px; position: relative;" color="amber" hover
                                    length="5" size="25" background-color="grey"></v-rating>
                            </div>
                            <div style="margin-top: 30px">
                                <GmapMap :center="{ lat: damas_lat, lng: damas_lng }" :zoom="12" map-type-id="terrain"
                                    style="height: 250px" @click="addMarker">
                                    <GmapMarker :position="marker" :clickable="true" />
                                </GmapMap>
                            </div>
                            <v-btn @click="getData" color="primary" :disabled="isSubmit && !response"
                                style="margin-top: 27px;height: 48px; border-radius: 10px; width: -webkit-fill-available">
                                <b-icon icon="search" style="margin-right: 10px; margin-left: 10px"></b-icon>
                                {{ $t('filter.search') }}
                                <v-progress-circular style="margin-left: 10px;margin-right: 10px" :size="20"
                                    v-if="isSubmit && !response" indeterminate color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn @click="reset()" color="accent"
                                style="margin-top: 27px;height: 48px; border-radius: 10px; width: -webkit-fill-available">
                                {{ $t('filter.reset') }}
                            </v-btn>
                        </div>
                    </v-card>
                </div>
                <div class="col-lg-8" v-if="(load2 && !empty)">
                    <div class="row">
                        <div class="col-sm-6 " v-for="(item, index) in displayedItems" :key="index">
                            <service-card :item="item" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-else-if="!load2"
                    style="display: flex;justify-content: center; align-items: center;">
                    <div class="progress-container">
                        <v-progress-circular indeterminate color="primary" :size="50"
                            style="margin-top: 100px"></v-progress-circular>
                    </div>
                </div>
                <div class="col-lg-8" v-else-if="load2 && empty"
                    style="display: flex;justify-content: center; align-items: center;">
                    <v-img src="@/assets/img/no-data.svg"></v-img>
                </div>
                <v-dialog v-model="dialogError" max-width="500px">
                    <v-card>
                        <v-spacer></v-spacer>
                        <v-card-title class="justify-content-center" style="padding-top: 30px">
                            {{ $t('error_message') }}
                        </v-card-title>
                        <v-card-actions style="padding-bottom: 30px">
                            <v-spacer></v-spacer>
                            <v-btn color="green" text @click="retry">{{ $t('retry') }}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <b-pagination v-model="currentPage" :total-rows="service.length" :per-page="itemsPerPage"
                aria-controls="service-list" align="center" style="margin-top: 60px;"></b-pagination>
        </div>
        <custom-footer />
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import ServiceCard from "@/components/Cards/ServiceCard.vue"
import Loadar2 from "@/components/Loadar2.vue"
export default {
    name: 'resEstateList',
    components: {
        Navbar,
        CustomFooter,
        ServiceCard,
        Loadar2
    },
    data: () => ({
        load: false,
        load2: false,
        error_snackbar: false,

        lang: null,
        direction: false,
        name: '',
        city_id: '',
        city_list: [],
        rating: 0,
        terms_list: [],
        terms_value: [],
        is_featured: "1",

        damas_lat: 33.5138,
        damas_lng: 36.2765,
        lat: null,
        long: null,
        marker: null,

        dialogError: false,


        sort_by_name_en: [
            {
                id: 0,
                name: "Descending"
            },
            {
                id: 1,
                name: "Ascending"
            },
        ],
        sort_by_name_ar: [
            {
                id: 0,
                name: "تنازلي"
            },
            {
                id: 1,
                name: "تصاعدي"
            },
        ],
        sort_by_name: [],
        sort_by_name_id: '',

        sort_by_date_en: [
            {
                id: 0,
                name: "By recent"
            },
            {
                id: 1,
                name: "By oldest"
            },
        ],
        sort_by_date_ar: [
            {
                id: 0,
                name: "الاحدث"
            },
            {
                id: 1,
                name: "الاقدم"
            },
        ],
        sort_by_date: [],
        sort_by_date_id: '',

        category_list: [],
        category_id: '',

        service: [],
        isSubmit: false,
        response: false,
        empty: false,
        currentPage: 1,
        itemsPerPage: 6
    }),
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        endIndex() {
            return this.startIndex + this.itemsPerPage - 1;
        },
        displayedItems() {
            return this.service.slice(this.startIndex, this.endIndex + 1);
        },
    },
    methods: {
        retry() {
            this.getData()
            this.dialogError = false
        },
        reset() {
            this.city_id = ''
            this.name = ''
            this.type = ''
            this.rating = 0
            this.is_featured = "0"
            this.lat = null
            this.long = null
            this.marker = null
            this.sort_by_name_id = ''
            this.sort_by_date_id = ''
            this.category_id = ''
            this.terms_value = []
        },
        addMarker(event) {
            this.marker = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }
            this.lat = this.marker.lat
            this.long = this.marker.lng
            console.log(this.marker)
        },
        getData() {
            this.load2 = false
            this.isSubmit = true
            this.response = false
            this.empty = false

            const formData = new FormData()
            formData.append('location_id', this.city_id)
            formData.append('search', this.name)
            formData.append('status', 1)
            console.log(this.rating)
            formData.append('rate', this.rating)
            formData.append('is_featured', Number(this.is_featured))
            formData.append('latitude', this.lat)
            formData.append('longitude', this.long)
            formData.append('sort_by_name', this.sort_by_name_id)
            formData.append('recent', this.sort_by_date_id)
            if (this.category_id == 'all')
                formData.append('category_id', '')
            else
                formData.append('category_id', this.category_id)

            for (let i = this.terms_value.length - 1; i >= 0; i--)
                if (!this.terms_value[i]) {
                    this.terms_value.splice(i, 1)
                }
            this.terms_value.forEach((item, index) => {
                formData.append(`terms[${index}]`, item)
            })
            // console.log(this.terms_value)

            this.axios.post(this.$store.state.url + "/api/v1/customer/services?lang=" + this.lang, formData)
                .then(res => {
                    this.load = true
                    this.load2 = true
                    this.response = true
                    console.log(res.data.list)
                    this.service = res.data.list

                    if (this.service.length == 0)
                        this.empty = true
                    this.isSubmit = false
                }).catch(error => {
                    this.service = []
                    if (!this.dialogError)
                        this.dialogError = true
                    this.load = true
                    this.load2 = true
                    this.response = true
                    this.empty = false
                    this.isSubmit = false
                })
        },
        getTerms() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/attributes/terms?lang=" + this.lang + "&type=service")
                .then(res => {
                    this.terms_list = res.data.list
                    // console.log(this.terms_list)
                });
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.direction = false
                this.sort_by_name = this.sort_by_name_en
                this.sort_by_date = this.sort_by_date_en
            }
            else {
                this.direction = true
                this.sort_by_name = this.sort_by_name_ar
                this.sort_by_date = this.sort_by_date_ar
            }
        },
        getCategory() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/services-categories?lang=" + this.lang)
                .then(res => {
                    this.category_list = res.data.list
                    if (this.lang == 'en') {
                        this.category_list.push({
                            name: "All",
                            id: 'all'
                        })
                    }
                    else {
                        this.category_list.push({
                            name: "الكل",
                            id: 'all'
                        })
                    }
                    this.category_list.reverse()
                    // console.log(this.category_list)
                });
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        this.category_id = this.$route.params.id

        this.initDir()
        this.getData()
        this.getCity()
        this.getTerms()
        this.getCategory()
    },

}
</script>

<style>
.v-application--is-ltr .v-input--selection-controls__input {
    margin-bottom: 8px;
}

.page-item.active .page-link {
    background-color: var(--v-primary-base) !important;
    border-color: var(--v-primary-base) !important;
    color: #fff !important;
}

.page-link {
    color: var(--v-primary-base) !important;
}

.v-list {
    text-align: justify;
}

.v-select.v-text-field--reverse .v-select__slot,
.v-select.v-text-field--reverse .v-select__selections {
    flex-direction: initial;
}

.list .list-content {
    padding: 50px 0px;
}

.list .list-image-service {
    margin-top: 78px;
    background-image: url(@/assets/img/1.webp);
    position: relative;
    height: 50vh;
    background-position: 70%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* opacity: 90%; */
}

.list .overlay-service {
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #3538978a, #09b23c6c);
    display: flex;
    justify-content: center;
    align-items: center;
}

.list .main-title {
    text-align: center;
    font-weight: bold;
    color: #fff;
}


.list .filter-card {
    box-shadow: 0 0 14px 6px #8080801a;
    margin: 20px 15px;
    padding: 20px
}


.list .property {
    margin-top: 20px
}

.v-application--is-ltr .v-input__prepend-outer,
.v-application--is-ltr .v-input__append-outer {
    align-items: center
}

.list .price {
    margin: 10px
}
</style>