<template>
    <div class="my-navbar">
        <b-navbar fixed toggleable="lg">
            <router-link to="/">
                <img src="../assets/img/logo7.png" alt="Logo" class="logo" />
            </router-link>
            <b-navbar-toggle target="nav-collapse">
                <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="x-lg"></b-icon>
                    <b-icon v-else icon="list"></b-icon>
                </template>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav style="justify-content: center;" class="my-collapse">
                <b-navbar-nav style="gap: 10px">
                    <b-nav-item :class="'custom_' + item.scroll" v-for="(item, index) in $t('navbar.title')" :key="index"
                        v-scroll-to="{
                            el: '#' + item.scroll,
                            offset: -66,
                            force: true,
                        }" @click="goToRoute(item.scroll)">
                        {{ item.text }}
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
            <div class="language">
                <!-- <v-btn color="primary" style=" text-transform: unset !important;">
                    Become a guide
                </v-btn> -->
                <b-dropdown dropdown>
                    <template #button-content>
                        <v-btn text class="advanced_search">
                            <b-icon icon="globe" class="global-icon"></b-icon>
                            <!-- {{ display_lang }} -->
                        </v-btn>
                    </template>
                    <b-dropdown-item @click="handleChange('ar')" v-if="lang == 'en'">العربية</b-dropdown-item>
                    <b-dropdown-item @click="handleChange('en')" v-if="lang == 'ar'">English</b-dropdown-item>
                </b-dropdown>
            </div>
        </b-navbar>
    </div>
</template>

<script>

export default {
    name: "Navbar",
    components: {
    },
    data() {
        return {
            en: "English",
            ar: "العربية",
            lang: "",
            scroll: "",
            section: "",
            view: {
                topOfPage: true,
            },
            lang_list: [
                {
                    name: "English",
                    id: 1
                },
                {
                    name: "العربية",
                    id: 2
                },
            ],
            screenWidth: 0,
            display_lang: "",
            lang: '',
            direction: false,
        };
    },
    computed: {
        isAr() {
            this.lang = localStorage.getItem("lang")
            return this.lang == 'ar'
        },
    },

    methods: {
        handleChange(selectedLang) {
            localStorage.setItem('lang', selectedLang)
            window.location.reload()
            console.log(selectedLang)
        },
        change_language() {
            localStorage.setItem("lang", "en")
        },
        handleScroll() {
            if (window.pageYOffset > 100) {
                if (this.view.topOfPage) {
                    this.view.topOfPage = false
                }
            } else {
                if (!this.view.topOfPage) {
                    this.view.topOfPage = true
                }
            }
        },
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateScreenWidth()
            });
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth
        },
        goToRoute(goal) {
            if (this.$route.name != "home" && goal != "contact") {
                this.$router.replace({ name: "home" })
            }
            this.scroll = goal;
            localStorage.setItem("scroll", this.scroll)
            if (goal == "resturant") this.$router.replace({ name: "resturants-list" })
            else if (goal == "public-service") this.$router.replace({ name: "service-list", params: { id: "all" } })
            else if (goal == "events") this.$router.replace({ name: "event-list" })
            else if (goal == "tourist-guide") this.$router.replace({ name: "tour-guid-list" })
        },
        mySection() {
            const sections = document.querySelectorAll('section')
            const navLi = document.querySelectorAll('nav div ul li')

            window.addEventListener('scroll', () => {
                let current = ''
                sections.forEach(section => {
                    const sectionTop = section.offsetTop
                    const sectionHeight = section.clientHeight
                    if (pageYOffset >= (sectionTop - sectionHeight / 3)) {
                        current = section.getAttribute('id')
                    }
                })
                navLi.forEach(li => {
                    li.firstChild.classList.remove('active')
                    if (li.classList.contains("custom_" + current))
                        li.firstChild.classList.add('active')
                })
            })
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.direction = false
                this.display_lang = "English"
            }
            else {
                this.direction = true
                this.display_lang = "العربية"
            }
        },
    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll)
    },
    mounted() {
        this.updateScreenWidth()
        this.onScreenResize()
        this.mySection()
        this.initDir()
    },
};
</script>

<style lang="scss">
@media (min-width: 991px) {
    html:lang(en) .my-collapse {
        margin-left: 0px;
    }

    html:lang(ar) .my-collapse {
        margin-right: 0px;
    }
}

a:hover {
    text-decoration: none !important;
}

.my-navbar .bi-search {
    font-size: 20px;
}

.my-navbar .bi-global {
    color: var(--v-primary-base);
}

.my-navbar .advanced_search {
    color: var(--v-primary-base);
    transition: all 0.3s;
}

.my-navbar .advanced_search:hover {
    color: var(--v-secondary-base);
    transition: all 0.3s;
}

html:lang(en) .my-navbar .bi-search {
    margin-right: 10px;
}


html:lang(ar) .my-navbar .bi-search {
    margin-left: 10px
}

// .my-navbar   .bi-search {
//     color: var(--v-primary-base);
//     transition: all 0.3s;
// }
// .my-navbar   .bi-search:hover {
//     color: var(--v-secondary-base);
//     transition: all 0.3s;
// }
@media(max-width: 992px) {
    .navbar {
        padding: 10px 20px !important;
    }
}

@media(min-width: 992px) {
    .navbar {
        padding: 10px 50px !important;
    }
}


.my-navbar .dropdown-menu {
    min-width: 7rem;
}

html:lang(en) .my-navbar .language {
    margin-right: 20px;
    // margin-top: 20px;
}

html:lang(ar) .my-navbar .language {
    margin-left: 20px;
    // margin-top: 20px;
}

.my-navbar .global-icon {
    font-size: 25px !important;
}

// .my-navbar   .global-icon {
//     color: var(--v-primary-base)
// }

// .my-navbar .global-icon:hover {
//     color: var(--v-secondary-base);
//     transition: all 0.3s;
// }

html:lang(en) .my-navbar .global-icon {
    margin-right: 5px;
    // margin-bottom: 3px;
}


.my-navbar .theme--light.v-select .v-select__selections {
    color: #fff !important
}

.my-navbar .bi-chevron-down {
    display: none !important;
}

.my-navbar .theme--light.v-label {
    color: #fff;
}

.my-navbar .theme--light.v-label {
    color: var(--v-primary-base) !important;
}

.my-navbar .theme--light.v-text-field--solo>.v-input__control>.v-input__slot {
    background: transparent;
    box-shadow: none;
}

.my-navbar .mdi-earth {
    color: #fff;
}

.my-navbar .mdi-earth {
    color: var(--v-primary-base) !important;
}

html:lang(en) .navbar-nav {
    direction: ltr;
}

html:lang(ar) .navbar-nav {
    direction: rtl;
}

@media (max-width: 991px) {
    .navbar-nav {
        padding-right: 0px;
    }
}


.navbar {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    transition: all 0.3s;
}

.my-navbar {
    padding: 0px;
    z-index: 1000000000;
    position: sticky;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.my-navbar .navbar-light .navbar-nav a.nav-link {
    color: #fff;
    font-size: 15px;
    // font-weight: 800;
    // border-bottom: 1px solid transparent;
    // transition: all 0.3s ease-in;
    letter-spacing: 1.5px;

    & {
        color: var(--v-primary-base);
    }
}

.my-navbar .navbar-light .navbar-nav .nav-link:focus {
    color: var(--v-secondary-base);
}

.my-navbar .navbar-light .navbar-nav a.nav-link {
    color: var(--v-primary-base) !important;
    // font-weight: 800;
    // transition: all 0.3s ease-in;
}

.my-navbar .navbar-light .navbar-nav a.nav-link:hover {
    color: var(--v-secondary-base);
    // border-bottom-color: var(--v-secondary-base) !important;
    transition: all 0.3s ease-in;
}

.active {
    // border-bottom-color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light .navbar-nav .section a.nav-link {
    color: #fff;
    // border-bottom-color: #fff !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light .navbar-nav a.nav-link:hover {
    color: var(--v-secondary-base) !important;
    // border-bottom-color: var(--v-secondary-base) !important;
    transition: all 0.2s ease-in;
}

.my-navbar .navbar-light .navbar-nav .section a.nav-link {
    // border-bottom-color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light {
    margin: 0px !important;
    // transition: all .5s ease-in;

    & {
        box-shadow: 0 0 10px #aaa;
        background-color: #fffffff7;
    }
}

@media (max-width: 991px) {
    .my-navbar .navbar {
        height: auto !important;
    }

    .my-navbar .navbar-light {
        background-color: #fffffff7;
        box-shadow: 0 0 10px #aaa;
    }

    .my-navbar .navbar-light .navbar-nav a.nav-link {
        color: var(--v-primary-base) !important;
        // font-weight: 800 !important;
    }

    .my-navbar .navbar-light .navbar-nav a.nav-link:hover {
        color: var(--v-secondary-base) !important;
        // border-bottom-color: var(--v-secondary-base) !important;
        transition: all 0.3s ease-in;
    }

    .my-navbar .btn {
        color: var(--v-primary-base) !important;
        transition: all 0.3s ease-in;
    }

    .my-navbar .language {
        display: none
    }

    html:lang(en) .my-navbar .logo {
        margin-left: 0px !important;
    }

    html:lang(ar) .my-navbar .logo {
        margin-right: 0px !important;
    }

}

.my-navbar .btn {
    width: 80px !important;
    background-color: transparent !important;
    border: none !important;
    // border-bottom: 1px solid transparent !important;
    border-radius: 0px !important;
    // font-weight: 800 !important;
    transition: all 0.3s ease-in;
}

.my-navbar .btn {
    color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .btn-secondary:focus,
.my-navbar .btn-secondary.focus {
    box-shadow: none !important;
}

.my-navbar .navbar-nav {
    margin-left: 0px;
    align-items: center;
}

.my-navbar ul {
    padding-left: 0px !important
}

.my-navbar .navbar-expand-lg {
    margin-right: 0.5vw;
}

.my-navbar .dropdown-toggle::after {
    display: none !important;
}

.my-navbar .dropdown .dropdown-menu {
    transition: all 0.3s ease-in;
    max-height: 0;
    display: block;
    opacity: 0;
}

.my-navbar .dropdown.show .dropdown-menu {
    max-height: 200px;
    opacity: 1;
}

html:lang(en) .my-navbar .logo {
    // margin-left: 40px;
    width: 180px;
}

html:lang(ar) .my-navbar .logo {
    // margin-right: 40px;
    width: 180px;
}

@media (max-width: 991px) {
    .my-navbar .hidden {
        display: none !important;
    }

    .my-navbar .logo {
        margin-left: 0px !important;
    }
}

.my-navbar .navbar .collapsed,
.my-navbar .navbar .not-collapsed {
    border: none;
    color: var(--v-primary-base) !important;
    font-size: 25px;
}

.my-navbar .navbar .not-collapsed {
    font-size: 25px;
    margin-top: 10px;
    margin-right: 5px;
}

.my-navbar .navbar .bi-list {
    font-size: 40px;
}



.my-navbar .bi-chevron-down {
    font-size: 15px !important;
}

.my-navbar .dropdown-item.active,
.my-navbar .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--v-primary-base) !important;
}

// .my-navbar .language {
//     top: -10px;
// }
</style>
