<template>
    <section class="resturant" id="resturant" dir="ltr">
        <div class="container">
            <div class="header">
                <h3 class="my-title d-flex justify-content-center">{{  $t('resturant.main_title') }}</h3>
                <p class="about-desc" style=""> 
                    {{ $t('resturant.desc') }}
                </p>
            </div>
            <div class="content" v-scrollanimation>
                <vueper-slides :touchable="false" :bullets="false" autoplay ref="vueperslides1" :gap="2"
                    :breakpoints="{ 700: { visibleSlides: 1, slideMultiple: 1 }, 992: { visibleSlides: 2, slideMultiple: 2 } }"
                    @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                    :visible-slides="3" fixed-height="430px">
                    <vueper-slide v-for="(item, i) in $store.state.restaurant_feature" :key="i"
                        @click.native="$refs.vueperslides1 && $refs.vueperslides1.goToSlide(i - 1)">
                        <template #content>
                            <restuarant-card :item="item"/>
                        </template>
                    </vueper-slide>
                </vueper-slides>
                <div class="row justify-content-center" style="margin-top: 30px">
                    <router-link to="/resturants/list">
                        <v-btn color="primary" elevation="2">{{ $t('see_all') }}<b-icon icon="chevron-double-right"
                                style="margin-left: 10px"></b-icon> </v-btn>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import RestuarantCard from "@/components/Cards/RestuarantCard.vue"
export default {
    components: {
        VueperSlides,
        VueperSlide,
        RestuarantCard
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
        // console.log(this.$store.state.restaurant_feature)
    },
}
</script>
<style lang="scss">
@media (max-width: 600px) {
    .vueperslides__arrow {
        display: none
    }
}

.resturant .my-title {
    color: var(--v-primary-base)
}

.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
    display: none !important;
}

.resturant .vueperslides__arrow {
    color: var(--v-primary-base)
}

.resturant {
    padding: 50px 30px;
    background: rgba(224, 224, 224, 0.356)
}

.resturant .row {
    row-gap: 30px
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.resturant .before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1.5s ease-out;
}

.resturant .enter {
    opacity: 1;
    transform: translateY(0px);
}
</style>