<template>
    <section class="service" id="public-service" dir="ltr">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-sm-4" v-for="(item, i) in $store.state.public_service" :key="i">
                    <div @click="display(item)">
                        <v-card>
                            <v-img :src="item.icon" class="service-icon" max-height="50" max-width="50"></v-img>
                            <div class="service-title">
                                {{ item.name }}
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    components: {
    },
    data() {
        return {

        };
    },
    methods: {
        display(item) {
            this.$router.replace({ name: 'service-list', params: { id: item.id } })
        }
    },
    mounted() {
    },
}
</script>
<style lang="scss">
.service .row {
    row-gap: 30px
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service .v-card {
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
    row-gap: 10px;
    border: none !important
}

.service .service-icon {
    transition: all .4s;
}

.service .v-card:hover {
    cursor: pointer;

    .service-icon {
        transform: translateY(-10px);
        transition: all .4s;
    }
}

.service .service-title {
    color: var(--v-primary-base);
}

.service .before-enter {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    transition: all 1s ease-out;
}

.service .enter {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}
</style>