
<template>
    <div class="landing" v-if="load">
        <navbar />
        <hero />
        <public-service />
        <resturants />
        <great />
        <event />
        <tour-guides />
        <counter />
        <application />
        <custom-footer />
        <!-- <city />
        <featured-real-estate />
        <about />
        <counter />
        <application />
        <custom-footer /> -->
        <!-- <copy /> -->
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import Hero from "@/components/Home Page/Hero.vue"
import Resturants from "@/components/Home Page/Resturants.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import TourGuides from '@/components/Home Page/TourGuides.vue'
import Event from "@/components/Home Page/Event.vue"
import Counter from "@/components/Home Page/Counter.vue"
import Application from "@/components/Home Page/Application.vue"
import Great from "@/components/Home Page/Great.vue"
import PublicService from "@/components/Home Page/PublicService.vue"
import Loadar2 from "@/components/Loadar2.vue"

export default {
    name: 'Landing',
    components: {
        Navbar,
        Hero,
        Resturants,
        CustomFooter,
        TourGuides,
        Event,
        Counter,
        Application,
        Great,
        PublicService,
        Loadar2,
    },
    data: () => ({
        lang: '',
        load: false,
    }),
    methods: {
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/config/home-page?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    console.log(res.data.list)
                    this.$store.state.public_service = res.data.list.serviceCategory
                    this.$store.state.restaurant_feature = res.data.list.featuredRestaurants
                    this.$store.state.occasions_feature = res.data.list.featuredOccasions
                    this.$store.state.tour_guids = res.data.list.tourGuides
                    this.scroll = localStorage.getItem("scroll")
                    console.log(this.scroll)
                    this.$scrollTo("#" + this.scroll)
                });
        },
    },
    mounted() {
        this.lang = localStorage.getItem('lang')
        document.documentElement.scrollTop = 0;
        this.getData()
    }

}
</script>

<style></style>