
<template>
    <div class="res-details" v-if="load">
        <navbar />
        <div class="details-image" dir="ltr">
            <div class="container">
                <div class="row" style="margin-top: 30px;row-gap: 20px">
                    <div class="col-lg-6">
                        <h1 class="main-title">{{ data.title }}</h1>
                        <div style="margin-top: 30px;text-align: left">
                            <v-row>
                                <v-rating style="position: relative;left: -10px;" :value="Number(data.star_rate)"
                                    color="amber" background-color="white" readonly size="30"></v-rating>
                            </v-row>

                            <!-- <v-row>
                                <v-btn color="accent" v-scroll-to="{
                                    el: '#reserve-form',
                                    offset: -66,
                                    force: true,
                                }">Reserve</v-btn>
                            </v-row> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-us" style="padding: 40px 0px">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-6 left">
                        <div v-scrollanimation>
                            <h4 class="my-title">{{ $t('details.details') }}</h4>
                            <div class="about-us-desc">
                                {{ data.content }}
                            </div>
                            <h4 class="my-title" style="margin-top: 30px">{{ $t('details.policy') }}</h4>
                            <div class="about-us-desc">
                                {{ data.policy }}
                            </div>
                            <a :href="'mailto:' + data.email">
                                <v-row style="margin-top: 30px">
                                    <b-icon icon="envelope-fill" class="about-restuarant-icon"></b-icon>
                                    <p class="about-restuarant">
                                        {{ data.email }}
                                    </p>
                                </v-row>
                            </a>
                            <a :href="'tel:' + data.phone">
                                <v-row style="margin-top: 10px">
                                    <b-icon icon="telephone-fill" class="about-restuarant-icon"></b-icon>
                                    <p class="about-restuarant">
                                        {{ data.phone }}
                                    </p>
                                </v-row>
                            </a>
                            <v-row style="margin-top: 10px">
                                <b-icon icon="alarm" class="about-restuarant-icon"></b-icon>
                                <p class="about-restuarant">
                                    {{ data.check_in_time.substring(0, 5) }}, {{ data.check_out_time.substring(0, 5) }}
                                </p>
                            </v-row>
                            <div style="margin-top: 30px;text-align: justify" v-if="attribute.length != 0">
                                <div v-for="(item, index) in attribute" :key="index">
                                    <h5 style="color: var(--v-primary-base)">{{ item.title }}</h5>
                                    <p style="color: gray" v-for="(item2, i) in item.content" :key="i">
                                        <b-icon icon="check2"></b-icon> {{ item2 }}
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="about-us-desc">
                                {{ $t('about.desc') }}
                            </div>
                            <div class="about-us-desc">
                                {{ $t('about.desc') }}
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-6 right" style="margin:  0px">
                        <vueper-slides ref="resturantImages" :touchable="false" fade :autoplay="false" :bullets="false"
                            @slide="$refs.resturantImages2.goToSlide($event.currentSlide.index, { emit: false })"
                            fixed-height="250px">
                            <vueper-slide v-for="(slide, i) in data.sub_media_urls" :key="i"
                                :image="slide.url"></vueper-slide>
                        </vueper-slides>
                        <vueper-slides class="no-shadow thumbnails" ref="resturantImages2" style="margin-top: 20px;"
                            @slide="$refs.resturantImages.goToSlide($event.currentSlide.index, { emit: false })"
                            :visible-slides="data.sub_media_urls.length" fixed-height="75px" :bullets="false"
                            :touchable="false" :gap="2.5" :arrows="false">
                            <vueper-slide v-for="(slide, i) in data.sub_media_urls" :key="i" :image="slide.url"
                                @click.native="$refs.resturantImages2.goToSlide(i)">
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="popular-dishes" style="background: #e0e0e05b;padding: 40px 0px;">
            <div class="container" v-scrollanimation>
                <div class="header">
                    <h4 class="my-title d-flex justify-content-center">Our Popular Dishes</h4>
                    <p class="about-desc">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et
                        dolore magna aliqua. Ut enim ad minim nostrud exercitation ullamco laboris nisi.
                    </p>
                </div>
                <vueper-slides :touchable="false" autoplay ref="dishcard" :gap="2" style="margin-top: 40px"
                    :breakpoints="{ 992: { visibleSlides: 2, slideMultiple: 2 }, 1500: { visibleSlides: 3, slideMultiple: 3 } }"
                    @slide="$refs.dishcard && $refs.dishcard.goToSlide($event.currentSlide.index, { emit: false })"
                    :visible-slides="3" fixed-height="350px">
                    <vueper-slide v-for="i in 6" :key="i" @click.native="$refs.dishcard && $refs.dishcard.goToSlide(i - 1)">
                        <template #content>
                            <v-card style="padding: 20px 0px">
                                <v-img src="@/assets/img/dish1.webp" aspect-ratio="1.9">
                                </v-img>
                                <div class="d-flex justify-content-center align-items-center"
                                    style="flex-direction: column;margin-top: 30px">
                                    <h5 style="font-weight: bold;">Dish Name</h5>
                                    <p style="color: var(--v-accent-base); font-size: 19px;">100$</p>
                                </div>
                            </v-card>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </div>
        </div> -->
        <div class="menu" style="padding: 40px 0px;background: #e0e0e05b;">
            <div class="container" v-scrollanimation>
                <div class="header">
                    <h4 class="my-title d-flex justify-content-center">{{ $t('details.our_food_menu') }}</h4>
                </div>
                <v-card style="padding: 15px;margin-top: 30px">
                    <div class="row align-items-center" style="justify-content: space-evenly;">
                        <v-img class="icon" src="@/assets/img/food.png" max-width="110px"></v-img>
                        <v-btn-toggle v-model="toggle" group color="primary">
                            <v-btn :value="item.id" v-for="(item, index) in menu" :key="index">
                                {{ item.name }}
                            </v-btn>
                        </v-btn-toggle>
                        <v-img class="icon" src="@/assets/img/drink.png" max-width="100px"></v-img>
                    </div>
                </v-card>
                <div class="row meals" style="margin-top: 60px;row-gap: 40px" v-if="meals.length != 0">
                    <div class="col-lg-6" v-for="(item, i) in meals" :key="i" >
                        <v-card>
                            <div class="row" style="flex-wrap: nowrap;">
                                <div class="col-sm-4"
                                    style="display: flex; justify-content: center;border-right: 1px solid #e0e0e0; min-height: 120px;">
                                    <v-img :src="item.media_urls[0]" :aspect-ratio="16 / 9"></v-img>
                                </div>
                                <div class="col-sm-8">
                                    <div class="row align-items-center">
                                        <div class="col-sm-8" style="padding-top: 25px;text-align: justify;">
                                            <h6 style="font-weight: bold;">{{ item.name }}</h6>
                                            <p style="color: gray; font-size: 13px;">{{ item.description }}</p>
                                        </div>
                                        <div class="col-sm-4" style="text-align: justify;">
                                            <p style="color: var(--v-accent-base); font-size: 20px;">{{ item.price }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
                <div class="row" v-else
                    style="margin-top: 60px;display: flex;justify-content: center; align-items: center;">
                    <div>
                        <h4>{{ $t('details.no_meals') }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="details-content">
            <div class="container">
                <div class="container">
                    <div class="header" style="margin-bottom: 20px">
                        <h4 class="my-title d-flex justify-content-center">{{ $t('details.location_details') }}</h4>
                    </div>
                    {{ data.location_name }}, {{ data.address }}
                    <GmapMap :center="{ lat: marker.lat, lng: marker.lng }" :zoom="12" map-type-id="terrain"
                        style="height: 300px;margin-top: 20px">
                        <GmapMarker :position="marker" />
                    </GmapMap>
                </div>
            </div>
        </div>
        <custom-footer />
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Loadar2 from "@/components/Loadar2.vue"

export default {
    name: 'ResturantsDetails',
    components: {
        Navbar,
        CustomFooter,
        Loadar2,
        VueperSlides,
        VueperSlide
    },
    data: () => ({
        data: null,
        lang: null,
        direction: false,
        load: false,
        marker: {
            lat: null,
            lng: null
        },
        toggle: null,
        menu: [],
        meals: [],
        attribute: []


    }),
    watch: {
        toggle() {
            console.log(this.toggle)
            this.menu.forEach(item => {
                if (item.id == this.toggle) {
                    console.log(item.meals)
                    this.meals = item.meals
                }
            })
        },
    },
    computed: {
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/resturants/" + this.id + "?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    this.data.sub_media_urls.push({
                        url: this.data.media_urls[0]
                    })
                    this.data.sub_media_urls.reverse()
                    console.log(this.data.sub_media_urls)
                    this.marker.lat = this.data.map_lat
                    this.marker.lng = this.data.map_lng
                    if (this.data.terms.length != 0) {
                        this.data.terms.forEach(item => {
                            item.core_terms.forEach(item2 => {
                                if (item2.status == true) {
                                    this.attribute.push({
                                        title: item.name,
                                        content: item2.name
                                    });
                                }
                            })
                        })
                    }
                    // console.log(this.attribute)

                    let mergedAttribute = [];
                    this.attribute.forEach(item => {
                        let existingItem = mergedAttribute.find(a => a.title === item.title);
                        if (existingItem) {
                            existingItem.content.push(item.content);
                        } else {
                            mergedAttribute.push({ title: item.title, content: [item.content] });
                        }
                    });
                    this.attribute = mergedAttribute
                    // console.log(this.attribute)
                });
        },
        getMenu() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/resturants/" + this.id + "/menus" + "?lang=" + this.lang)
                .then(res => {
                    this.menu = res.data.list
                    if (this.menu.length != 0)
                        this.toggle = this.menu[0].id
                    console.log("MMMMMMMMMM")
                    console.log(this.menu)
                });
        },
    },
    created() {
        document.documentElement.scrollTop = 0
        this.id = this.$route.params.id
        this.initDir()
        this.getData()
        this.getMenu()
    },

}
</script>

<style>
.thumbnails {
    margin: auto;
    max-width: 300px;
}

.thumbnails .vueperslide {
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: 0.3s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
}

.thumbnails .vueperslide--active {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-color: #000;
}

html:lang(en) .res-details .about-restuarant-icon {
    margin-top: 3px;
    margin-right: 10px;
    color: gray;
}

html:lang(ar) .res-details .about-restuarant-icon {
    margin-top: 3px;
    margin-left: 10px;
    color: gray;
}


.res-details .about-restuarant {
    color: gray;
    /* transition: all .4s; */
}

.v-btn-toggle--group>.v-btn.v-btn {
    margin: 0px
}

@media (max-width: 767px) {
    .breakfast {
        display: none
    }
}

.about-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
    text-align: center;
    max-width: 700px
}

.res-details .about-us-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
}

.res-details .details-content {
    padding: 30px 0px;
    text-align: justify
}

.res-details .details-image {
    /* background: var(--v-primary-base); */
    background-image: url(@/assets/img/back12.jpg);
    position: relative;
    height: 40vh;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    margin-top: 78px;
}


.res-details .overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #13436aa2, #0c273ac5);
}

.res-details .main-title {
    text-align: justify;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 992px) {
    .details .phone {
        text-align: end;
    }
}

@media (max-width: 992px) {
    html:lang(ar) .res-details .phone {
        text-align: start;
    }

    .res-details .about-image,
    .res-details .reserve-image {
        display: none;
    }

    .res-details .icon {
        display: none;
    }

    .res-details .about-us {
        margin-top: 30px
    }
}

.res-details .my-title {
    color: var(--v-primary-base);
    text-align: justify;
}

.vue-map {
    border-radius: 5px;
}

.res-details .bi-check-lg,
.res-details .bi-check-square {
    color: var(--v-secondary-base);
    margin: 0px 10px;
}

html:lang(en) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .enter,
html:lang(en) .about-us .left .enter {
    opacity: 1;
    transform: translateX(0px);
}

html:lang(en) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .enter,
html:lang(en) .about-us .right .enter {
    opacity: 1;
    transform: translateX(0px);
}


.popular-dishes .before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1.5s ease-out;
}

.popular-dishes .enter {
    opacity: 1;
    transform: translateY(0px);
}

.menu .before-enter {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    transition: all 1s ease-out;
}

.menu .enter {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}
</style>