<template>
    <div>
        <div class="custom-footer" id="contact">
            <div class="container">
                <div class="row " style="row-gap: 20px; text-align: justify;">
                    <div class="col-lg-5 footer-col">
                        <router-link to="/">
                            <v-img src="@/assets/img/logo7.png" max-width="200px"></v-img>
                        </router-link>
                        <p class="footer-desc">{{ $t('footer.desc') }}</p>
                        <ul class="social-links">
                            <li>
                                <a class="social facebook" target="_blank" href="https://www.facebook.com">
                                    <b-icon icon="facebook"></b-icon>
                                </a>
                            </li>
                            <li>
                                <a class="social whatsapp" target="_blank" href="https://wa.me/00963999999999">
                                    <b-icon icon="whatsapp"></b-icon>
                                </a>
                            </li>
                            <li>
                                <a class="social linkedin" target="_blank" href="https://www.linkedin.com">
                                    <b-icon icon="linkedin"></b-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 footer-col">
                        <h5 class="footer-title">
                            {{ $t('footer.explore') }}
                        </h5>
                        <ul class="footer-ul">
                            <li style="margin-top: 18px" :class="'custom_' + item.scroll"
                                v-for="(item, index) in  $t('navbar.title')" :key="index" v-scroll-to="{
                                    el: '#' + item.scroll,
                                    offset: -66,
                                    force: true,
                                }" @click="goToRoute(item.scroll)">
                                {{ item.text }}
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3 footer-col">
                        <h5 class="footer-title">
                            {{ $t('footer.contact') }}
                        </h5>
                        <ul class="footer-ul">
                            <li>
                                <a class="row list-contact-item" target="_blank" href="https://www.google.com/maps">
                                    <b-icon class="icon-contact" icon="geo-alt-fill" aria-hidden="true"></b-icon>
                                    <p>{{ $t('footer.location') }}</p>
                                </a>
                            </li>
                            <li>
                                <a class="row list-contact-item" :href="'mailto:info@tourism.sy'">
                                    <b-icon class="icon-contact" icon="envelope-fill" aria-hidden="true"></b-icon>
                                    <p>info@tourism.sy</p>
                                </a>
                            </li>
                            <li>
                                <a class="row list-contact-item" :href="'tel:+963 999999999'">
                                    <b-icon class="icon-contact" icon="telephone-fill" aria-hidden="true"></b-icon>
                                    <p style="direction: ltr">+963 999999999</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <copy />
    </div>
</template>
<script>

import Copy from "@/components/Copy.vue"
export default {
    components: {
        Copy
    },
    data() {
        return {
        };
    },
    methods: {
        goToRoute(goal) {
            if (this.$route.name != "home" && goal != "contact") {
                this.$router.replace({ name: "home" })
            }
            this.scroll = goal;
            localStorage.setItem("scroll", this.scroll)
            // if (goal == "real-estate-list") this.$router.replace({ name: "real-estate-list" })
            // if (goal == "portfolio") this.$router.replace({ name: "portfolio" })
        },
    },
    mounted() {
    },
}
</script>
<style lang="scss">
.custom-footer {
    padding: 50px;
    background: rgba(224, 224, 224, 0.356)
}

@media (max-width: 767px) {
    .custom-footer {
        padding: 50px 20px !important;
    }
}

.custom-footer .footer-desc {
    color: #919191;
    margin-top: 20px;
    font-size: 15px;
}

.custom-footer .footer-title {
    color: var(--v-primary-base);
    font-weight: bold;
    margin-bottom: 30px;
}

.custom-footer .footer-ul {
    padding-left: 0px !important;
}

.custom-footer .footer-ul li {
    color: #919191;
    list-style: none;
    font-size: 14px;
    transition: all .5s;
}

html:lang(en) .custom-footer .footer-ul li:hover {
    margin-left: 10px;
    cursor: pointer;
    color: var(--v-primary-base);
    transition: all .5s;
}

html:lang(ar) .custom-footer .footer-ul li:hover {
    margin-right: 10px;
    cursor: pointer;
    color: var(--v-primary-base);
    transition: all .5s;
}

// .custom-footer .footer-col {
// padding: 0px 40px
// }

.custom-footer .list-contact-item {
    transition: all 0.5s;
    color: #919191;
    margin: auto -5px !important;
}

html:lang(en) .custom-footer .list-contact-item:hover {
    color: var(--v-primary-base);
    margin-left: -5px !important;
    transition: all 0.5s;
    text-decoration: none;
    cursor: pointer;
}

html:lang(ar) .custom-footer .list-contact-item:hover {
    color: var(--v-primary-base);
    margin-right: -5px !important;
    transition: all 0.5s;
    text-decoration: none;
    cursor: pointer;
}

.custom-footer .icon-contact {
    padding: 3px 10px;
    margin-right: 15px;
    // color: var(--main-blue);
}

.custom-footer .social-links {
    margin-top: 20px;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 100;
    padding: 0px;
}

.custom-footer .social {
    border-radius: 50%;
    padding: 10px;
    font-size: 17px;
    // color: var(--v-secondary-base);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    transition: transform 1s;
}

.custom-footer .facebook {
    border: 1px solid #c4c4c4;
    color: #919191;
    transition: all .5s;
}

.custom-footer .facebook:hover {
    color: white;
    border: 1px solid var(--v-primary-base);
    background: var(--v-primary-base);
    transition: all .5s;
}

.custom-footer .whatsapp {
    border: 1px solid #c4c4c4;
    color: #919191;
    transition: all .5s;
}

.custom-footer .whatsapp:hover {
    color: white;
    border: 1px solid var(--v-accent-base);
    background: var(--v-accent-base);
    transition: all .5s;
}

.custom-footer .linkedin {
    border: 1px solid #c4c4c4;
    color: #919191;
    transition: all .5s;
}

.custom-footer .linkedin:hover {
    color: white;
    border: 1px solid var(--v-primary-base);
    background: var(--v-primary-base);
    transition: all .5s;
}
</style>