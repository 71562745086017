import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRating } from 'vuetify/lib/components/VRating';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto service-card",attrs:{"max-width":"350"}},[_c(VImg,{attrs:{"height":"200","src":_vm.item.mainImage.url}}),_c(VChip,{staticStyle:{"postion":"relative","bottom":"180px","left":"25px"},attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.item.category.name)+" ")]),_c(VCardTitle,{staticStyle:{"justify-content":"space-between","bottom":"30px","position":"relative"},attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.item.name)+" "),_c(VRating,{attrs:{"value":Number(_vm.item.star_rate),"color":"amber","background-color":"grey","dense":"","readonly":"","size":"16"}})],1),_c(VCardText,{staticStyle:{"bottom":"30px","position":"relative"}},[_c('div',{staticClass:"text-subtitle-1",staticStyle:{"padding-bottom":"0px"}},[_c('b-icon',{attrs:{"icon":"geo-alt"}}),_vm._v(" "+_vm._s(_vm.item.location.name)+", "+_vm._s(_vm.item.address)+" ")],1),_c('div',{staticClass:"row",staticStyle:{"margin":"0px 0px"}},[_c('b-icon',{staticClass:"service-icon",staticStyle:{"margin-top":"3px"},attrs:{"icon":"cash"}}),_vm._v(" "+_vm._s(_vm.item.price)+" ")],1)]),_c(VCardActions,{staticStyle:{"bottom":"30px","position":"relative"}},[_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.display(_vm.item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('display'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }