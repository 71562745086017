<template>
    <section class="great">
        <div class="container">
            <div class="row greate-row">
                <h2 class="one-title">{{ $t('great.main') }}</h2>
                <h2 class="two-title">{{ $t('great.second') }}</h2>
            </div>
        </div>
    </section>
</template>
<script>


export default {
    components: {

    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    },
}
</script>
<style lang="scss">
.great {
    background-image: url(@/assets/img/great2.jpg);
    position: relative;
    z-index: 0;
    padding: 130px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.great .one-title {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}

.great .two-title {
    color: var(--v-secondary-base);
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px
}

.great .greate-row {

    flex-direction: column;
    justify-content: center;
    align-items: center
}
</style>