import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-navbar"},[_c('b-navbar',{attrs:{"fixed":"","toggleable":"lg"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/img/logo7.png"),"alt":"Logo"}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"},scopedSlots:_vm._u([{key:"default",fn:function({ expanded }){return [(expanded)?_c('b-icon',{attrs:{"icon":"x-lg"}}):_c('b-icon',{attrs:{"icon":"list"}})]}}])}),_c('b-collapse',{staticClass:"my-collapse",staticStyle:{"justify-content":"center"},attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticStyle:{"gap":"10px"}},_vm._l((_vm.$t('navbar.title')),function(item,index){return _c('b-nav-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                        el: '#' + item.scroll,
                        offset: -66,
                        force: true,
                    }),expression:"{\n                        el: '#' + item.scroll,\n                        offset: -66,\n                        force: true,\n                    }"}],key:index,class:'custom_' + item.scroll,on:{"click":function($event){return _vm.goToRoute(item.scroll)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1),_c('div',{staticClass:"language"},[_c('b-dropdown',{attrs:{"dropdown":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c(VBtn,{staticClass:"advanced_search",attrs:{"text":""}},[_c('b-icon',{staticClass:"global-icon",attrs:{"icon":"globe"}})],1)]},proxy:true}])},[(_vm.lang == 'en')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleChange('ar')}}},[_vm._v("العربية")]):_vm._e(),(_vm.lang == 'ar')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleChange('en')}}},[_vm._v("English")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }