<template>
    <div class="loadar" dir="ltr">
        <img src="@/assets/img/logo2.webp" style="width:80px;z-index:1000" />
        <div class='pulse'></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        };
    },
    methods: {

    },
    mounted() {
    }
}
</script>
<style lang="scss">
.loadar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}


.pulse {
    background: rgba(0,0,0,0.2);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 50.18%;
    top: 51.2%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: 0;
}

.pulse:after {
    content: "";
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: absolute;
    margin: -15px 0 0 -15px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #89849ba4;
    animation-delay: .5s;
}

@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
}</style>