<template>
    <div class="guid-list" v-if="load">
        <navbar />
        <div class="guid-list-image">
            <div class="overlay">
                <h1 class="main-title">{{ $t('tour.main_title') }}</h1>
            </div>
        </div>
        <div class="guid-list-content container">
            <div class="row">
                <div class="col-lg-4">
                    <v-card class="filter-card" style="text-align: justify">
                        <h4>{{ $t('filter.property') }}</h4>
                        <v-divider></v-divider>
                        <div class="property">
                            <v-radio-group row v-model="type">
                                <v-radio :label="$t('filter.translation')" value="translator"></v-radio>
                                <v-radio :label="$t('filter.tour')" value="tourist guide"></v-radio>
                            </v-radio-group>

                            <v-text-field outlined :reverse="direction" v-model="name"
                                :label="$t('filter.name')"></v-text-field>

                            <v-select outlined v-model="city_id" :reverse="direction" :items="city_list" item-text="name"
                                item-value="id" :label="$t('filter.city')"></v-select>

                            <v-select outlined multiple v-model="lang_value" :reverse="direction" :items="lang_list"
                                item-text="name" item-value="id" :label="$t('filter.language')"></v-select>
                            <v-select outlined v-model="sort_by_name_id" :reverse="direction" :items="sort_by_name"
                                item-text="name" item-value="id" :label="$t('filter.sort_by_name')"></v-select>
                            <v-btn @click="getData" color="primary" :disabled="isSubmit && !response"
                                style="margin-top: 27px;height: 48px; border-radius: 10px; width: -webkit-fill-available">
                                <b-icon icon="search" style="margin-right: 10px; margin-left: 10px"></b-icon>
                                {{ $t('filter.search') }}
                                <v-progress-circular style="margin-left: 10px;margin-right: 10px" :size="20"
                                    v-if="isSubmit && !response" indeterminate color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn @click="reset()" color="accent"
                                style="margin-top: 27px;height: 48px; border-radius: 10px; width: -webkit-fill-available">
                                {{ $t('filter.reset') }}
                            </v-btn>
                        </div>
                    </v-card>
                </div>
                <div class="col-lg-8" v-if="(load2 && !empty)">
                    <div class="row" style="row-gap: 0px;">
                        <div class="col-sm-6 " v-for="(item, index) in displayedItems" :key="index">
                            <tour-guid-card :item="item" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-8" v-else-if="!load2"
                    style="display: flex;justify-content: center; align-items: center;">
                    <div class="progress-container">
                        <v-progress-circular indeterminate color="primary" :size="50"
                            style="margin-top: 100px"></v-progress-circular>
                    </div>
                </div>
                <div class="col-lg-8" v-else-if="load2 && empty"
                    style="display: flex;justify-content: center; align-items: center;">
                    <v-img src="@/assets/img/no-data.svg"></v-img>
                </div>
                <v-dialog v-model="dialogError" max-width="500px">
                    <v-card>
                        <v-spacer></v-spacer>
                        <v-card-title class="justify-content-center" style="padding-top: 30px">
                            {{ $t('error_message') }}
                        </v-card-title>
                        <v-card-actions style="padding-bottom: 30px">
                            <v-spacer></v-spacer>
                            <v-btn color="green" text @click="retry">{{ $t('retry') }}
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <b-pagination v-model="currentPage" :total-rows="tours.length" :per-page="itemsPerPage"
                aria-controls="restuarant-list" align="center" style="margin-top: 60px;"></b-pagination>
        </div>
        <custom-footer />
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import TourGuidCard from "@/components/Cards/TourGuidCard.vue"
import Loadar2 from "@/components/Loadar2.vue"

export default {
    name: 'guid-list',
    components: {
        Navbar,
        CustomFooter,
        TourGuidCard,
        Loadar2
    },
    data: () => ({
        load: false,
        load2: false,
        error_snackbar: false,

        min: 100,
        max: 500,
        range: [200, 450],
        resestate: [],
        lang: null,
        direction: false,
        name: '',
        city_id: '',
        city_list: [],
        type: '',
        lang_list: [],
        lang_value: [],

        dialogError: false,


        tours: [],
        isSubmit: false,
        response: false,
        empty: false,
        currentPage: 1,
        itemsPerPage: 4,
        sort_by_name_en: [
            {
                id: 0,
                name: "Descending"
            },
            {
                id: 1,
                name: "Ascending"
            },
        ],
        sort_by_name_ar: [
            {
                id: 0,
                name: "تنازلي"
            },
            {
                id: 1,
                name: "تصاعدي"
            },
        ],
        sort_by_name: [],
        sort_by_name_id: ''
    }),
    computed: {
        startIndex() {
            return (this.currentPage - 1) * this.itemsPerPage;
        },
        endIndex() {
            return this.startIndex + this.itemsPerPage - 1;
        },
        displayedItems() {
            return this.tours.slice(this.startIndex, this.endIndex + 1);
        },
    },
    methods: {
        retry() {
            this.getData()
            this.dialogError = false
        },
        reset() {
            this.city_id = ''
            this.name = ''
            this.type = ''
            this.sort_by_name_id = ''
            this.lang_value = []
        },
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en') {
                this.direction = false
                this.sort_by_name = this.sort_by_name_en
            }
            else {
                this.direction = true
                this.sort_by_name = this.sort_by_name_ar
            }
        },
        getData() {
            this.load2 = false
            this.isSubmit = true
            this.response = false
            this.empty = false

            const formData = new FormData()
            formData.append('city_id', this.city_id)
            formData.append('search', this.name)
            formData.append('status', 1)
            formData.append('type', this.type)
            formData.append('sort_by_name', this.sort_by_name_id)
            this.lang_value.forEach((item, index) => {
                formData.append(`languages[${index}]`, item)
            })

            this.axios.post(this.$store.state.url + "/api/v1/customer/tourGuide?lang=" + this.lang, formData)
                .then(res => {
                    this.load = true
                    this.load2 = true
                    this.response = true
                    this.tours = res.data.list
                    if (this.tours.length == 0)
                        this.empty = true
                    this.isSubmit = false
                }).catch(error => {
                    this.tours = []
                    if (!this.dialogError)
                        this.dialogError = true
                    this.load = true
                    this.load2 = true
                    this.response = true
                    this.empty = false
                    this.isSubmit = false
                })
        },
        getCity() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/cities?lang=" + this.lang)
                .then(res => {
                    this.city_list = res.data.list
                });
        },
        getLanguage() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/languages?lang=" + this.lang)
                .then(res => {
                    this.lang_list = res.data.list
                });
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        this.initDir()
        this.getData()
        this.getCity()
        this.getLanguage()
    },

}
</script>

<style>
.v-card__title{
    cursor: auto;
}
.mdi-radiobox-blank {
    margin-top: 8px
}

.v-guid-list {
    text-align: justify;
}

.v-select.v-text-field--reverse .v-select__slot,
.v-select.v-text-field--reverse .v-select__selections {
    flex-direction: initial;
}

.guid-list .guid-list-content {
    padding: 50px 0px;
}

.guid-list .guid-list-image {
    margin-top: 78px;
    background-image: url(@/assets/img/guid6.jpg);
    position: relative;
    height: 50vh;
    background-position: 20%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    opacity: 90%;
}

.guid-list .overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #09b23c77, #3538978a);
    display: flex;
    justify-content: center;
    align-items: center;
}

.guid-list .main-title {
    text-align: center;
    font-weight: bold;
    color: #fff;
}


.guid-list .filter-card {
    box-shadow: 0 0 14px 6px #8080801a;
    margin: 20px 15px;
    padding: 20px
}


.guid-list .property {
    margin-top: 20px
}

.v-application--is-ltr .v-input__prepend-outer,
.v-application--is-ltr .v-input__append-outer {
    align-items: center
}

.guid-list .price {
    margin: 10px
}

.guid-list .v-radio .v-label {
    top: 5px;
}

.guid-list .v-input--radio-group--row {
    margin-top: 0px;
}
</style>