

<template>
    <div class="service-details" v-if="load">
        <navbar />
        <div class="details-image-service" :style="{ backgroundImage: `url(${data.category.thumbnail})` }">
            <div class="overlay">
                <div class="container">
                    <div class="row" style="margin-top: 60px;row-gap: 20px">
                        <div class="col-lg-6 row" style="column-gap: 20px">
                            <h1 class="main-title">{{ data.title }}</h1>
                            <v-chip color="accent" style="margin-top: 8px">
                                {{ data.category.name_en }}
                            </v-chip>
                        </div>
                    </div>
                    <div style="margin-top: 30px;text-align: justify">
                        <v-row>
                            <v-rating :value="Number(data.star_rate)" color="amber" background-color="white" readonly
                                size="30"></v-rating>
                        </v-row>
                    </div>
                </div>
            </div>

        </div>
        <div class="about-us" style="padding: 40px 0px">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-6 left">
                        <div v-scrollanimation>
                            <h4 class="my-title">{{ $t('details.details') }}</h4>
                            <div class="about-us-desc">
                                {{ data.content }}
                            </div>
                            <h4 class="my-title" style="margin-top: 30px">{{ $t('details.policy') }}</h4>
                            <div class="about-us-desc">
                                {{ data.policy }}
                            </div>
                            <!-- <v-row style="margin-top: 10px">
                                <b-icon icon="geo-alt-fill" class="about-service-icon"></b-icon>
                                <p class="about-service">
                                    {{ data.location.name }}, {{ data.address }}
                                </p>
                            </v-row> -->
                            <v-row style="margin-top: 20px">
                                <b-icon icon="cash" class="about-service-icon"></b-icon>
                                <p class="about-service">
                                    {{ data.price }}
                                </p>
                            </v-row>
                            <a :href="'tel:' + data.phone">
                                <v-row style="margin-top: 10px">
                                    <b-icon icon="telephone-fill" class="about-service-icon"></b-icon>
                                    <p class="about-service">
                                        {{ data.phone }}
                                    </p>
                                </v-row>
                            </a>
                            <a :href="'mailto:' + data.email">
                                <v-row style="margin-top: 10px">
                                    <b-icon icon="envelope-fill" class="about-service-icon"></b-icon>
                                    <p class="about-service">
                                        {{ data.email }}
                                    </p>
                                </v-row>
                            </a>
                            <div style="margin-top: 30px;text-align: justify" v-if="attribute.length != 0">
                                <div v-for="(item, index) in attribute" :key="index">
                                    <h5 style="color: var(--v-primary-base)">{{ item.title }}</h5>
                                    <p style="color: gray" v-for="(item2, i) in item.content" :key="i">
                                        <b-icon icon="check2"></b-icon> {{ item2 }}
                                    </p>
                                </div>
                            </div>
                            <div style="margin-top: 30px;text-align: justify" v-if="data.features.length != 0">
                                <div v-for="(item, index) in data.features" :key="index">
                                    <h5 style="color: var(--v-primary-base)">{{ item.title }}</h5>
                                    <p style="color: gray">
                                        <b-icon icon="check2"></b-icon> {{ item.content }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 right" style="margin:  0px">
                        <vueper-slides ref="serviceImages" :touchable="false" fade :autoplay="false" :bullets="false"
                            @slide="$refs.serviceImages2.goToSlide($event.currentSlide.index, { emit: false })"
                            fixed-height="250px">
                            <vueper-slide v-for="(slide, i) in data.media" :key="i" :image="slide.url">
                            </vueper-slide>
                        </vueper-slides>
                        <vueper-slides class="no-shadow thumbnails" ref="serviceImages2" style="margin-top: 20px;"
                            @slide="$refs.serviceImages.goToSlide($event.currentSlide.index, { emit: false })"
                            :visible-slides="data.media.length" fixed-height="75px" :bullets="false" :touchable="false"
                            :gap="2.5" :arrows="false">
                            <vueper-slide v-for="(slide, i) in data.media" :key="i" :image="slide.url"
                                @click.native="$refs.serviceImages2.goToSlide(i)">
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
            </div>
        </div>
        <div class="details-content" style=" background: #f2f2f252;">
            <div class="container">
                <div class="container">
                    <div class="header" style="margin-bottom: 20px">
                        <h4 class="my-title d-flex justify-content-center">{{ $t('details.location_details') }}</h4>
                    </div>
                    {{ data.location.name }}, {{ data.address }}
                    <GmapMap :center="{ lat: marker.lat, lng: marker.lng }" :zoom="12" map-type-id="terrain"
                        style="height: 300px;margin-top: 20px">
                        <GmapMarker :position="marker" />
                    </GmapMap>
                </div>
            </div>
        </div>
        <custom-footer />
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Loadar2 from "@/components/Loadar2.vue"

export default {
    name: 'serviceDetails',
    components: {
        Navbar,
        CustomFooter,
        Loadar2,
        VueperSlides,
        VueperSlide
    },
    data: () => ({
        data: null,
        lang: null,
        direction: false,
        load: false,
        marker: {
            lat: null,
            lng: null
        },
        attribute: []
    }),
    computed: {
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/services/" + this.id + "?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    this.data.media.push(this.data.mainImage)
                    this.data.media.reverse()
                    console.log(res.data.list)
                    this.marker.lat = parseFloat(this.data.map_lat)
                    this.marker.lng = parseFloat(this.data.map_lng)
                    if (this.data.terms.length != 0) {
                        this.data.terms.forEach(item => {
                            item.core_terms.forEach(item2 => {
                                if (item2.status == true) {
                                    this.attribute.push({
                                        title: item.name,
                                        content: item2.name
                                    });
                                }
                            })
                        })
                    }
                    // console.log(this.attribute)

                    let mergedAttribute = [];
                    this.attribute.forEach(item => {
                        let existingItem = mergedAttribute.find(a => a.title === item.title);
                        if (existingItem) {
                            existingItem.content.push(item.content);
                        } else {
                            mergedAttribute.push({ title: item.title, content: [item.content] });
                        }
                    });
                    this.attribute = mergedAttribute
                    // console.log(this.attribute)

                });
        },
    },
    created() {
        document.documentElement.scrollTop = 0
        this.id = this.$route.params.id
        this.initDir()
        this.getData()
    },

}
</script>

<style>
.thumbnails {
    margin: auto;
    max-width: 300px;
}

.thumbnails .vueperslide {
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: 0.3s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
}

.thumbnails .vueperslide--active {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-color: #000;
}

html:lang(en) .service-details .about-service-icon {
    margin-top: 3px;
    margin-right: 10px;
    color: gray;
}

html:lang(ar) .service-details .about-service-icon {
    margin-top: 3px;
    margin-left: 10px;
    color: gray;
}

.service-details .about-service {
    color: gray;
}

.v-btn-toggle--group>.v-btn.v-btn {
    margin: 0px
}

@media (max-width: 767px) {
    .breakfast {
        display: none
    }
}

.about-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
    text-align: center;
    max-width: 700px
}

.service-details .about-us-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
}

.service-details .details-content {
    padding: 30px 0px;
    text-align: justify
}

.service-details .details-image-service {
    /* background: var(--v-primary-base); */
    position: relative;
    height: 40vh;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    margin-top: 78px;
}


.service-details .overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #35389762, #09b23c48);
}

.service-details .main-title {
    text-align: justify;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 992px) {
    .service-details .phone {
        text-align: end;
    }
}

@media (max-width: 992px) {
    html:lang(ar) .service-details .phone {
        text-align: start;
    }

    .service-details .about-image,
    .service-details .reserve-image {
        display: none;
    }

    .service-details .icon {
        display: none;
    }

    .service-details .about-us {
        margin-top: 30px
    }
}

.service-details .my-title {
    color: var(--v-primary-base);
    text-align: justify;
}

.vue-map {
    border-radius: 5px;
}

.service-details .bi-check-lg,
.service-details .bi-check-square {
    color: var(--v-secondary-base);
    margin: 0px 10px;
}

html:lang(en) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .enter,
html:lang(en) .about-us .left .enter {
    opacity: 1;
    transform: translateX(0px);
}

html:lang(en) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .enter,
html:lang(en) .about-us .right .enter {
    opacity: 1;
    transform: translateX(0px);
}


.popular-dishes .before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1.5s ease-out;
}

.popular-dishes .enter {
    opacity: 1;
    transform: translateY(0px);
}

.menu .before-enter {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    transition: all 1s ease-out;
}

.menu .enter {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}
</style>