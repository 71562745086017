

<template>
    <div class="details" v-if="load">
        <navbar />
        <div class="details-image-event">
            <div class="container">
                <div class="row" style="margin-top: 50px;row-gap: 20px">
                    <div class="col-lg-6">
                        <h1 class="main-title">{{ data.title }}</h1>
                        <div style="margin-top: 30px;text-align: left">
                            <v-row>
                                <h4 class="row" style="color: #fff" v-if="data.resturant != null">{{ data.resturant.name }}
                                    <div class="show-details" @click="show_resturant(data.resturant.id)"> ({{$t('details.show_details')}}) </div>
                                </h4>
                                <h4 class="row" style="color: #fff" v-else>{{ data.service.name }}
                                    <div class="show-details" @click="show_service(data.service.id)"> ({{$t('details.show_details')}}) </div>
                                </h4>
                            </v-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-us" style="padding: 40px 0px">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-6 left">
                        <div v-scrollanimation>
                            <h4 class="my-title">{{$t('details.details')}}</h4>
                            <div class="about-us-desc">
                                {{ data.description }}
                            </div>
                            <v-row style="margin-top: 20px">
                                <b-icon icon="geo-alt-fill" class="about-event-icon"></b-icon>
                                <p class="about-event">
                                    {{ data.city.name }}, {{ data.location }}
                                </p>
                            </v-row>
                            <v-row style="margin-top: 10px">
                                <b-icon icon="cash" class="about-event-icon"></b-icon>
                                <p class="about-event">
                                    {{ data.price }}
                                </p>
                            </v-row>
                            <v-row style="margin-top: 10px">
                                <b-icon icon="calendar-date" class="about-event-icon"></b-icon>
                                <p class="about-event">
                                    {{ data.start_date.substring(0, 10) }}, {{ data.end_date.substring(0, 10) }}
                                </p>
                            </v-row>
                        </div>
                    </div>
                    <div class="col-lg-6 right" style="margin:  0px">
                        <vueper-slides ref="eventImages" :touchable="false" fade :autoplay="false" :bullets="false"
                            @slide="$refs.eventImages2.goToSlide($event.currentSlide.index, { emit: false })"
                            fixed-height="250px">
                            <vueper-slide v-for="(slide, i) in data.media" :key="i" :image="slide.url">
                            </vueper-slide>
                        </vueper-slides>
                        <vueper-slides class="no-shadow thumbnails" ref="eventImages2" style="margin-top: 20px;"
                            @slide="$refs.eventImages.goToSlide($event.currentSlide.index, { emit: false })"
                            :visible-slides="data.media.length" fixed-height="75px" :bullets="false" :touchable="false"
                            :gap="2.5" :arrows="false">
                            <vueper-slide v-for="(slide, i) in data.media" :key="i" :image="slide.url"
                                @click.native="$refs.eventImages2.goToSlide(i)">
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
            </div>
        </div>
        <custom-footer />
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Loadar2 from "@/components/Loadar2.vue"

export default {
    name: 'EventDetails',
    components: {
        Navbar,
        CustomFooter,
        Loadar2,
        VueperSlides,
        VueperSlide
    },
    data: () => ({
        data: null,
        lang: null,
        direction: false,
        load: false,
    }),
    computed: {
    },
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/occasion/" + this.id + "?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    this.data.media.push(this.data.media_urls)
                    this.data.media.reverse()
                    console.log(this.data.media)
                });
        },
        show_service(id){
            this.$router.replace({ name: 'service-details', params: { id: id } })
        },
        show_resturant(id){
            this.$router.replace({ name: 'resturants-details', params: { id: id } })
        }
    },
    created() {
        document.documentElement.scrollTop = 0
        this.id = this.$route.params.id
        this.initDir()
        this.getData()
    },

}
</script>

<style>
.thumbnails {
    margin: auto;
    max-width: 300px;
}

.thumbnails .vueperslide {
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: 0.3s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
}

.thumbnails .vueperslide--active {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-color: #000;
}
.details .show-details{
    font-size: 18px; 
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: all .3s;
    margin-top: 5px;
}

.details .show-details:hover{
    text-decoration: underline;
    transition: all .3s;
}

html:lang(en) .details .about-event-icon {
    margin-top: 3px;
    margin-right: 10px;
    color: gray;
}
html:lang(ar) .details .about-event-icon {
    margin-top: 3px;
    margin-left: 10px;
    color: gray;
}

.details .about-event {
    color: gray;
}

.v-btn-toggle--group>.v-btn.v-btn {
    margin: 0px
}

@media (max-width: 767px) {
    .breakfast {
        display: none
    }
}

.about-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
    text-align: center;
    max-width: 700px
}

.details .about-us-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
}

.details .details-content {
    padding: 30px 0px;
    text-align: justify
}

.details .details-image-event {
    /* background: var(--v-primary-base); */
    background-image: url(@/assets/img/event4.jpg);
    position: relative;
    height: 45vh;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    margin-top: 78px;
}


.details .overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #13436aa2, #0c273ac5);
}

.details .main-title {
    text-align: justify;
    font-weight: bold;
    color: #fff;
}

@media (min-width: 992px) {
    .details .phone {
        text-align: end;
    }
}

@media (max-width: 992px) {
    html:lang(ar) .details .phone {
        text-align: start;
    }

    .details .about-image,
    .details .reserve-image {
        display: none;
    }

    .details .icon {
        display: none;
    }

    .details .about-us {
        margin-top: 30px
    }
}

.details .my-title {
    color: var(--v-primary-base);
    text-align: justify;
}

.vue-map {
    border-radius: 5px;
}

.details .bi-check-lg,
.details .bi-check-square {
    color: var(--v-secondary-base);
    margin: 0px 10px;
}

html:lang(en) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .enter,
html:lang(en) .about-us .left .enter {
    opacity: 1;
    transform: translateX(0px);
}

html:lang(en) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .enter,
html:lang(en) .about-us .right .enter {
    opacity: 1;
    transform: translateX(0px);
}


.popular-dishes .before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1.5s ease-out;
}

.popular-dishes .enter {
    opacity: 1;
    transform: translateY(0px);
}

.menu .before-enter {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    transition: all 1s ease-out;
}

.menu .enter {
    opacity: 1;
    transform: scale3d(1, 1, 1);
}
</style>