import Vue from 'vue'
import VueRouter from 'vue-router'

import Landing from "@/views/Landing.vue"
import ResturantsDetails from "@/views/Resturant/ResturantsDetails.vue"
import ResturantsList from "@/views/Resturant/ResturantsList.vue"
import TourGuidList from "@/views/TourGuid/TourGuidList.vue"
import GuidDetails from "@/views/TourGuid/GuidDetails.vue"

import EventList from "@/views/Event/EventList.vue"
import EventDetails from "@/views/Event/EventDetails.vue"

import ServiceList from "@/views/Service/ServiceList.vue"
import ServiceDetails from "@/views/Service/ServiceDetails.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Landing
  },
  {
    path: '/resturants/details/:id',
    name: 'resturants-details',
    component: ResturantsDetails
  },
  {
    path: '/resturants/list',
    name: 'resturants-list',
    component: ResturantsList
  },
  {
    path: '/tour-guid/list',
    name: 'tour-guid-list',
    component: TourGuidList
  },
  {
    path: '/guid/details/:id',
    name: 'guid-details',
    component: GuidDetails
  },

  {
    path: '/event/list',
    name: 'event-list',
    component: EventList
  },
  {
    path: '/event/details/:id',
    name: 'event-details',
    component: EventDetails
  },
  {
    path: '/service/list/:id',
    name: 'service-list',
    component: ServiceList
  },
  {
    path: '/service/details/:id',
    name: 'service-details',
    component: ServiceDetails
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
