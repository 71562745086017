<template>
    <section class="guides" id="tourist-guide" dir="ltr">
        <div class="container">
            <div class="header">
                <h3 class="my-title d-flex justify-content-center" style="text-align: center">{{ $t('tour.main_title') }}</h3>
                <p class="about-desc" style="">
                    {{ $t('tour.desc') }} 
                </p>
            </div>
            <div class="content" style="margin-top: 30px" v-scrollanimation>
                <vueper-slides :touchable="false" :bullets="false" autoplay ref="guides" :gap="2"
                    :breakpoints="{ 700: { visibleSlides: 1, slideMultiple: 1 }, 992: { visibleSlides: 2, slideMultiple: 2 }, }"
                    @slide="$refs.guides && $refs.guides.goToSlide($event.currentSlide.index, { emit: false })"
                    :visible-slides="3" fixed-height="385px">
                    <vueper-slide v-for="(item, i) in $store.state.tour_guids" :key="i"
                        @click.native="$refs.guides && $refs.guides.goToSlide(i - 1)">
                        <template #content>
                            <tour-guid-card :item="item" />
                        </template>
                    </vueper-slide>
                </vueper-slides>
                <div class="row justify-content-center" style="margin-top: 30px">
                    <router-link to="/tour-guid/list">
                        <v-btn color="primary" elevation="2">{{ $t('see_all') }}<b-icon icon="chevron-double-right"
                                style="margin-left: 10px"></b-icon> </v-btn>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import TourGuidCard from "@/components/Cards/TourGuidCard.vue"
export default {
    components: {
        VueperSlides,
        VueperSlide,
        TourGuidCard
    },
    data() {
        return {
        };
    },
    methods: {
        display(id) {
            this.$router.replace({ name: 'guid-details', params: { id: id } })
        }
    },
    mounted() {
        console.log(this.$store.state.tour_guids)
    }
}
</script>
<style lang="scss">
.guides .my-title {
    color: var(--v-primary-base)
}

.guides {
    padding: 50px 30px;
}


.guides .before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 1.5s ease-out;
}

.guides .enter {
    opacity: 1;
    transform: translateY(0px);
}
</style>