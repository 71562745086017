
<template>
    <div class="guid-details" v-if="load">
        <navbar />
        <div class="details-image">
            <div class="overlay">
                <div class="container">
                    <div class="row" style="margin-top: 10px;row-gap: 20px">
                        <div class="col-lg-3 col-md-4 col-sm-5">
                            <v-img :src="data.media.url" width="180px" height="180px" :aspect-ratio="16 / 9"
                                style="position: relative;top: 15px; border-radius: 50%;"></v-img>
                        </div>
                        <div class="col-lg-9 col-md-8 col-sm-7">
                            <div class="row align-items-center" style="margin-top: 30px;column-gap: 10px">
                                <h2 class="main-title">{{ data.fname }} {{ data.lname }}</h2>
                                <v-row style="column-gap: 10px">
                                    <div v-for="(item, i) in data.type" :key="i">
                                        <v-chip color="accent" v-if="item == 'translator'">
                                            {{ translator }}
                                        </v-chip>
                                        <v-chip color="primary" v-if="item == 'tourist guide'">
                                            {{ guid }}
                                        </v-chip>
                                    </div>
                                </v-row>
                            </div>
                            <div style="margin-top: 15px;text-align: left">
                                <!-- -->
                                <v-row style="margin-top: 20px">
                                    <b-icon icon="geo-alt-fill" class="guid-icon"
                                        style="color: #fff;font-size: 30px;"></b-icon>
                                    <p style="color: #fff; cursor: pointer;">
                                        {{ data.city.name }}, {{ data.address }}
                                    </p>
                                </v-row>
                                <a :href="'tel:' + data.phone">
                                    <v-row style="margin-top: 10px">
                                        <b-icon icon="telephone-fill" class="guid-icon"
                                            style="color: #fff;font-size: 30px;"></b-icon>
                                        <p style="color: #fff; cursor: pointer;">{{ data.phone }}</p>
                                    </v-row>
                                </a>
                                <a :href="'mailto:' + data.email">
                                    <v-row style="margin-top: 10px">
                                        <b-icon icon="envelope-fill" class="guid-icon"
                                            style="color: #fff;font-size: 30px;"></b-icon>
                                        <p style="color: #fff; cursor: pointer;">{{ data.email }}</p>
                                    </v-row>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-us">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 left">
                        <v-card style="padding-bottom: 20px;box-shadow: none;">
                            <v-card-title class="my-title title-line">{{ $t('details.details') }}</v-card-title>
                            <v-card-subtitle style="margin-top: 40px;text-align:justify">
                                {{ data.description }}
                            </v-card-subtitle>
                            <v-card-subtitle class="row" style="column-gap: 10px">
                                <p style="margin-right: 3px">
                                    {{ $t('filter.language') }}:
                                </p>
                                <v-chip color="secondary" v-for="(item, index) in data.language" :key="index">
                                    {{ item.name }}
                                </v-chip>
                                <!-- <p style="margin-right: 5px" v-for="(item, index) in data.language" :key="index">
                                    {{ item.name }} 
                                </p> -->
                            </v-card-subtitle>
                            <!-- <v-card-subtitle>
                                
                            </v-card-subtitle> -->

                            <!-- <v-card-subtitle style="margin-top: 20px">
                                <ul class="row guid-social-links">
                                    <li>
                                        <a class="guid-social guid-facebook" target="_blank">
                                            <b-icon icon="facebook"></b-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="guid-social guid-whatsapp" target="_blank">
                                            <b-icon icon="whatsapp"></b-icon>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="guid-social guid-linkedin" target="_blank">
                                            <b-icon icon="linkedin"></b-icon>
                                        </a>
                                    </li>
                                </ul>
                            </v-card-subtitle> -->
                        </v-card>
                    </div>
                    <div class="col-lg-5 right">
                        <v-card style="box-shadow: none;">
                            <v-card-title class="my-title title-line">{{ $t('details.testimonials') }}</v-card-title>
                            <v-carousel style="margin-top: 50px" cycle height="auto" hide-delimiters show-arrows-on-hover>
                                <v-carousel-item v-for="i in 4" :key="i">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4">
                                            <v-img src="@/assets/img/user2.jpg" max-width="100px"
                                                style="border-radius: 50%;"></v-img>
                                        </div>
                                        <div class="col-lg-8"
                                            style="color: rgba(0, 0, 0, 0.6);font-size: 15px;text-align:justify">
                                            "{{ $t('tour.desc') }}"
                                            <v-rating :value="4" background-color="grey" color="amber" dense readonly
                                                style="margin-top: 10px" size="14"></v-rating>
                                        </div>
                                    </div>
                                </v-carousel-item>
                            </v-carousel>
                        </v-card>
                        <!-- <v-card>
                            <v-card-title class="my-title">
                                Language
                            </v-card-title>
                            <v-card-subtitle style="margin-top: 0px">
                                <ul>
                                    <li>Arabic</li>
                                    <li>English</li>
                                    <li>French</li>
                                </ul>
                            </v-card-subtitle>
                        </v-card> -->
                    </div>
                </div>
            </div>
        </div>
        <custom-footer />
    </div>
    <loadar-2 v-else />
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CustomFooter from "@/components/CustomFooter.vue"
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Loadar2 from "@/components/Loadar2.vue"

export default {
    name: 'guidDetails',
    components: {
        Navbar,
        CustomFooter,
        VueperSlides,
        VueperSlide,
        Loadar2
    },
    data: () => ({
        data: null,
        lang: null,
        direction: false,
        load: false,
        translator: '',
        guid: ''
    }),
    methods: {
        initDir() {
            this.lang = localStorage.getItem('lang')
            if (this.lang == 'en')
                this.direction = false
            else
                this.direction = true
        },
        getData() {
            this.axios.get(this.$store.state.url + "/api/v1/customer/tourGuide/" + this.id + "?lang=" + this.lang)
                .then(res => {
                    this.load = true
                    this.data = res.data.list
                    console.log(this.data)
                });
        },
    },
    created() {
        document.documentElement.scrollTop = 0;
        this.id = this.$route.params.id
        this.initDir()
        this.getData()
        if (this.lang == "en") {
            this.translator = "Translation"
            this.guid = "Tour Guides"
        }
        else {
            this.translator = "ترجمة"
            this.guid = "دليل سياجي"
        }

    },

}
</script>

<style>
.about-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
    text-align: center;
    max-width: 700px
}

.guid-details .about-us-desc {
    color: #919191;
    margin-top: 20px;
    text-align: justify;
    font-size: 14px;
}

.guid-details .details-content {
    text-align: justify
}

.guid-details .details-image {
    /* background: var(--v-primary-base); */
    background-image: url(@/assets/img/guid-back.jpg);
    position: relative;
    height: 40vh;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    margin-top: 78px;
    opacity: 70%
}


@media (max-width: 575px) {
    .guid-details .details-image {
        height: 85vh !important;
    }
}

.guid-details .about-us {
    padding: 20px 0px;
}

@media (max-width: 992px) {
    .guid-details .about-us {
        padding: 0px !important;
    }
}

.guid-details .overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(270deg, #09b23c9d, #3538979a);
}

.guid-details .main-title {
    text-align: justify;
    font-weight: bold;
    color: #fff;
}

.guid-details .title-line {
    padding-bottom: 0px
}

.guid-details .title-line::before {
    content: "";
    position: absolute;
    width: 50px;
    top: 150px;
    height: 3px;
    margin-top: -85px;
    background: var(--v-accent-base);
}

@media (min-width: 992px) {
    .guid-details .phone {
        text-align: end;
    }
}

@media (max-width: 992px) {
    html:lang(ar) .guid-details .phone {
        text-align: start;
    }

    .guid-details .about-image,
    .guid-details .reserve-image {
        display: none;
    }

    .guid-details .icon {
        display: none;
    }

    .guid-details .about-us {
        margin-top: 30px;
        margin-bottom: 30px
    }
}


.guid-details .my-title {
    color: var(--v-primary-base);
}

.guid-details .guid-social-links {
    width: 100%;
    column-gap: 30px;
    list-style: none;
    transition: all 0.2s ease;
    padding-left: 0px !important;
}

.guid-details .guid-social {
    border-radius: 50%;
    padding: 8px;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    transition: transform 1s;
}

.guid-details .guid-facebook {
    color: white;
    border: 1px solid var(--v-primary-base);
    background: var(--v-primary-base);
    transition: all .5s;
}

.guid-details .guid-facebook:hover {
    transform: rotate(50deg);
    transition: all .5s;
}

.guid-details .guid-whatsapp {
    color: white;
    border: 1px solid var(--v-accent-base);
    background: var(--v-accent-base);
    transition: all .5s;
}

.guid-details .guid-whatsapp:hover {
    transform: rotate(50deg);
    transition: all .5s;
}

.guid-details .guid-linkedin {
    color: white;
    border: 1px solid var(--v-primary-base);
    background: var(--v-primary-base);
    transition: all .5s;
}

.guid-details .guid-linkedin:hover {
    transform: rotate(50deg);
    transition: all .5s;
}


.vue-map {
    border-radius: 5px;
}

.guid-details .bi-check-lg,
.guid-details .bi-check-square {
    color: var(--v-secondary-base);
    margin: 0px 10px;
}

html:lang(en) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .left .enter,
html:lang(en) .about-us .left .enter {
    opacity: 1;
    transform: translateX(0px);
}

html:lang(en) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .before-enter {
    opacity: 0;
    transform: translateX(-30px);
    transition: all 1s ease;
}

html:lang(ar) .about-us .right .enter,
html:lang(en) .about-us .right .enter {
    opacity: 1;
    transform: translateX(0px);
}
</style>