<template>
    <v-card class="guid-card mx-auto" max-width="344" v-if="item != null">
        <v-img :src="item.media.url" class="overlay" height="200px">
            <ul class="row social-links">
                <li>
                    <a class="social email" :href="'mailto:' + item.email">
                        <b-icon class="email" icon="envelope-fill" aria-hidden="true"></b-icon>
                    </a>
                </li>
                <li>
                    <a class="social phone" :href="'tel:' + item.phone">
                        <b-icon class="phone" icon="telephone-fill" aria-hidden="true"></b-icon>
                    </a>
                </li>
            </ul>
        </v-img>
        <v-card-title @click="display(item.id)">
            {{ item.fname }} {{ item.lname }}
        </v-card-title>
        <v-card-subtitle class="row" style="margin-top: 0px;">
            <b-icon class="guid-icon" icon="geo-alt-fill" style="margin-top: 3px;"></b-icon>
            {{ item.city.name }}, {{ item.address }}
        </v-card-subtitle>
        <v-card-subtitle class="row" style="column-gap: 10px;margin-bottom: 10px;margin-top: 0px;">
            <div v-for="(item2, i) in item.type" :key="i">
                <v-chip color="accent" v-if="item2 == 'translator' ">
                    {{ translator }}
                </v-chip>
                <v-chip color="primary" v-if="item2 == 'tourist guide'">
                    {{ guid }}
                </v-chip>
            </div>
        </v-card-subtitle>
    </v-card>
</template>
<script>

export default {
    props: ["item"],
    data() {
        return {
            lang: '',
            translator: '',
            guid: ''
        };
    },
    methods: {
        display(id) {
            this.$router.replace({ name: 'guid-details', params: { id: id } })
        },
    },
    mounted() {
        // console.log(this.item)
        this.lang = localStorage.getItem("lang")
        if(this.lang == "en"){
            this.translator = "Translation"
            this.guid = "Tour Guides"
        }
        else{
            this.translator = "ترجمة"
            this.guid = "دليل سياجي"
        }

    }
}
</script>
<style lang="scss">
html:lang(en) .guid-icon{
    margin-right: 10px;
}
html:lang(ar) .guid-icon{
    margin-left: 10px
}

.guid-card .contact-ul {
    padding-left: 0px !important;
}

.guid-card .contact-ul li {
    color: #919191;
    list-style: none;
    font-size: 14px;
    transition: all .5s;
}

.guid-card .contact-li {
    transition: all 0.5s;
    color: #919191;
    margin: auto -5px !important;
}

.guid-card .contact-icon {
    padding: 3px 10px;
    margin-right: 15px;
    // color: var(--main-blue);
}

.guid-card:hover .v-image__image {
    transform: scale(1.2, 1.2);
    opacity: .7;
    transition: all .5s;
}

.guid-card:hover .social-links {
    opacity: 1;
    visibility: visible;
    bottom: 0;
    transition-delay: 0.1s;
}

.guid-card {
    overflow: hidden;
    margin: 20px 15px;
}

.guid-card .v-image__image {
    transition: all .5s;
}

.guid-card .v-card__title {
    transition: all .5s;
    cursor: pointer;
}

.guid-card .v-card__title:hover {
    cursor: pointer;
    color: var(--v-accent-base);
    transition: all .5s;
}


.guid-card .social-links {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 30px;
    justify-content: center;
    column-gap: 30px;
    list-style: none;
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
}

.guid-card .social {
    border-radius: 50%;
    padding: 10px;
    font-size: 17px;
    // color: var(--v-secondary-base);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    transition: transform 1s;
}

.guid-card .email {
    color: white;
    border: 1px solid var(--v-primary-base);
    background: var(--v-primary-base);
    transition: all .5s;
}

.guid-card .email:hover {
    transform: rotate(50deg);
    transition: all .5s;
}

.guid-card .phone {
    color: white;
    border: 1px solid var(--v-accent-base);
    background: var(--v-accent-base);
    transition: all .5s;
}

.guid-card .phone:hover {
    transform: rotate(50deg);
    transition: all .5s;
}

</style>
