<template>
    <v-card class="mx-auto resturant-card" max-width="350">
        <v-img height="200" :src="item.media_urls[0]"></v-img>
        <v-card-title color="primary" style="justify-content: space-between;">{{ item.name }}
            <v-rating :value="Number(item.star_rate)" color="amber" background-color="grey" dense readonly size="16"></v-rating>
        </v-card-title>
        <v-card-text>
            <!-- <v-row align="center" class="mx-0">
            </v-row> -->
            <div class="text-subtitle-1" style="padding-bottom: 0px;">
                <b-icon icon="geo-alt"></b-icon>
                {{ item.location_name }}, {{ item.address }}
            </div>
            <div class="row" style="margin: 0px 0px">
                <b-icon icon="alarm" class="res-icon" style="margin-top: 3px;"></b-icon>
                {{ item.check_in_time.substring(0, 5) }}, {{ item.check_out_time.substring(0, 5) }}
            </div>
            <!-- <div>{{ item.content }}</div> -->
        </v-card-text>
        <v-card-actions>
            <v-btn color="accent" text @click="display(item.id)">
                {{ $t('display') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

export default {
    props: ["item"],
    data() {
        return {
        };
    },
    methods: {
        display(id) {
            this.$router.replace({ name: 'resturants-details', params: { id: id } })
        },
    },
    mounted() {
    }
}
</script>
<style lang="scss">
html:lang(en) .res-icon{
    margin-right: 5px;
}
html:lang(ar) .res-icon{
    margin-left: 5px
}
.resturant-card.v-card {
    box-shadow: 0 0 14px 6px #8080801a;
    height: 400px;
    transition: all .4s;
    margin: 20px 15px;
}

.resturant-card.v-card:hover {
    box-shadow: 0px 11px 14px rgb(106 106 106 / 40%);
    transform: translateY(-10px);
    transition: all .4s;
}

.resturant-card .v-card__title {
    color: var(--v-primary-base);
    transition: all .4s;
    font-size: 16px;
}

.resturant-card .v-card__text {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: start;
}
</style>
