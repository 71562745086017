import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"event-card mx-auto",staticStyle:{"margin-top":"30px"},attrs:{"max-width":"400"}},[_c(VImg,{staticClass:"white--text align-end",attrs:{"height":"200px","src":_vm.item.media_urls.url}}),_c(VCardTitle,{staticStyle:{"color":"var(--v-primary-base)"}},[_vm._v(_vm._s(_vm.item.title))]),_c(VCardSubtitle,{staticClass:"pb-2"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"10px"}},[_c('b-icon',{staticClass:"event-icon",staticStyle:{"margin-top":"3px"},attrs:{"icon":"geo-alt-fill"}}),_vm._v(" "+_vm._s(_vm.item.city.name)+", "+_vm._s(_vm.item.location)+" ")],1),_c('div',{staticClass:"row",staticStyle:{"margin-top":"15px"}},[_c('b-icon',{staticClass:"event-icon",staticStyle:{"margin-top":"3px"},attrs:{"icon":"calendar-date"}}),_vm._v(" "+_vm._s(_vm.item.start_date.substring(0, 10))+", "+_vm._s(_vm.item.end_date.substring(0, 10))+" ")],1),_c('div',{staticClass:"row",staticStyle:{"margin-top":"15px"}},[_c('b-icon',{staticClass:"event-icon",staticStyle:{"margin-top":"3px"},attrs:{"icon":"cash"}}),_vm._v(" "+_vm._s(_vm.item.price)+" ")],1)]),_c(VCardActions,{staticClass:"btn-card",staticStyle:{"margin-top":"10px"}},[_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.display(_vm.item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('explore'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }