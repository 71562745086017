<template>
    <v-card class="mx-auto service-card" max-width="350">
        <v-img height="200" :src="item.mainImage.url"></v-img>
        <v-chip color="accent" style="postion:relative; bottom: 180px;left : 25px">
            {{ item.category.name }}
        </v-chip>
        <v-card-title color="primary" style="justify-content: space-between;bottom: 30px;position: relative;">
            {{ item.name }}
            <v-rating :value="Number(item.star_rate)" color="amber" background-color="grey" dense readonly
                size="16"></v-rating>
        </v-card-title>
        <v-card-text style="bottom: 30px;position: relative;">
            <div class="text-subtitle-1" style="padding-bottom: 0px;">
                <b-icon icon="geo-alt"></b-icon>
                {{ item.location.name }}, {{ item.address }}
            </div>
            <div class="row" style="margin: 0px 0px">
                <b-icon icon="cash" class="service-icon" style="margin-top: 3px;"></b-icon>
                {{ item.price }}
            </div>
        </v-card-text>
        <v-card-actions style="bottom: 30px;position: relative;">
            <v-btn color="accent" text @click="display(item.id)">
                {{ $t('display') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>

export default {
    props: ["item"],
    data() {
        return {
        };
    },
    methods: {
        display(id) {
            this.$router.replace({ name: 'service-details', params: { id: id } })
        },
    },
    mounted() {
    }
}
</script>
<style lang="scss">
html:lang(en) .service-icon{
    margin-right: 5px
}
html:lang(ar) .service-icon{
    margin-left: 5px
}
.service-card.v-card {
    box-shadow: 0 0 14px 6px #8080801a;
    height: 400px;
    transition: all .4s;
    margin: 20px 15px;
}

.service-card.v-card:hover {
    box-shadow: 0px 11px 14px rgb(106 106 106 / 40%);
    transform: translateY(-10px);
    transition: all .4s;
}

.service-card .v-card__title {
    color: var(--v-primary-base);
    transition: all .4s;
    font-size: 16px;
}

.service-card .v-card__text {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: start;
}

</style>
